import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import { Button } from "rsuite";
import { Input } from "antd";
import axios from "axios";
import _ from "lodash";
import backendAPI from "./../../utils/backendAPI";

const SmallText = styled.div`
  color: var(--neutral-gray-400, #97a1af);
  font-size: 14px;
  font-family: Lato;
  font-weight: 500;
  margin-bottom: 10px;
`;

const BigText = styled.div`
  color: var(--neutral-gray-700, #344051);
  font-size: 16px;
  font-family: Lato;
  font-weight: 500;
`;

const SmallLetter = styled.div`
  color: var(--neutral-gray-400, #97a1af);
  font-size: 12px;
  font-family: Lato;
  font-weight: 400;
`;

const BackBtn = styled(Button)`
  border: 1px solid #414e62 !important;
  border-radius: 8px !important;
  width: 115px !important;
  height: 56px !important;
  padding: 16px !important;
  color: var(--neutral-900, #0f172a) !important;
  font-size: 16px !important;
  font-family: Lato !important;
  font-weight: 600 !important;
  background-color: white !important;
`;

const NextBtn = styled(Button)`
  background: #40bf7f !important;
  border: 1px solid !important;
  border-color: #40bf7f !important;
  border-radius: 8px !important;
  width: 131px !important;
  height: 56px !important;
  padding: 16px !important;
  color: #fff !important;
`;

const Wrapper = styled.div`
  padding: 144px 40px 0 40px;
`;

const Header = styled.div`
  color: var(--neutral-gray-700, #344051);
  font-size: 24px;
  font-family: Lato;
  font-weight: 700;
  letter-spacing: 0.6px;
  padding-left: 40px;
  padding-bottom: 23px;
  border-bottom: 1px solid #e4e7ec;
`;

const Body = styled.div`
  padding-top: 40px;
  padding-left: 40px;
`;
const Footer = styled.div`
  padding: 200px 35px 83px 35px;
  justify-content: space-between;
  display: flex;
  align-items: center;
`;

const Flex = styled.div`
  display: flex;
  align-items: flex-start;
`;

const NameInput = styled(Input)`
  height: 40px;
  width: 260px;
  margin-top: 4px;
  margin-bottom: 50px;
`;

const OrangeButton = styled(Button)`
  border-radius: 29px !important;
  border: 1px solid #fe6601 !important;
  background: #fff !important;
  color: #fe6601 !important;
  font-size: 12px !important;
  font-family: Lato !important;
  font-weight: 700 !important;
  letter-spacing: 0.3px !important;
`;

function formatDate(date) {
  const options = { day: "numeric", month: "short", year: "numeric" };
  const formattedDate = new Date(date).toLocaleDateString("en-US", options);
  const day = new Date(date).getDate();
  let suffix = "th";

  if (day === 1 || day === 21 || day === 31) {
    suffix = "st";
  } else if (day === 2 || day === 22) {
    suffix = "nd";
  } else if (day === 3 || day === 23) {
    suffix = "rd";
  }

  return formattedDate.replace(/\b\d{1,2}\b/, `${day}${suffix}`);
}

const EditAppliance = () => {
  const id = useParams();
  const [appliance, setAppliance] = useState({});
  const [name, setName] = useState(appliance.name);
  const loadAppliance = async () => {
    try {
      const response = await axios.get(
        `${backendAPI.address}/api/v1/appliances/${id.id}/`
      );
      setAppliance(response.data);
      setName(response.data.name);
    } catch (err) {
      console.log(err);
    }
  };
  const saveFunc = async () => {
    try {
      const response = await axios.patch(
        `${backendAPI.address}/api/v1/appliances/${id.id}/`,
        {
          name: name,
        }
      );
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    loadAppliance();
  }, []);
  return (
    <Wrapper>
      <Header>
        Edit Appliance #{id.id} ({appliance.name})
      </Header>
      <Body>
        <SmallText>Name</SmallText>
        <NameInput
          placeholder="Appliance Name"
          value={name}
          onChange={(event) => {
            setName(event.target.value);
          }}
        />
        <Flex>
          <div>
            <SmallText>Serial Number</SmallText>
            <BigText>{appliance.serial_number}</BigText>
            <SmallText style={{ marginTop: 140 }}>Model</SmallText>
            <BigText>{_.get(appliance.appliance_type, "name")}</BigText>
          </div>
          <div style={{ marginLeft: 127 }}>
            <SmallText>Location</SmallText>
            <BigText>{_.get(appliance.location, "name")}</BigText>
            <SmallText style={{ marginTop: 140 }}>
              Current Firmware Version
            </SmallText>
            <BigText>
              {_.get(appliance.appliance_type, "latest_firmware_version")}
            </BigText>
            <SmallLetter style={{ marginBottom: 8 }}>
              Update available
            </SmallLetter>
            <OrangeButton style={{ padding: "8px 20px" }} appearance="subtle">
              Schedule Update
            </OrangeButton>
          </div>
          <div style={{ marginLeft: 162 }}>
            <SmallText>License Key</SmallText>
            <BigText>{appliance.license_key}</BigText>
            <SmallLetter style={{ marginBottom: 4 }}>
              Valid till {formatDate(appliance.expiry_date)}
            </SmallLetter>
            <OrangeButton style={{ padding: "8px 20px" }} appearance="subtle">
              Extend
            </OrangeButton>
            <SmallText style={{ marginTop: 76 }}>Status</SmallText>
            {appliance.connection_status ? (
              <BigText style={{ color: "#40bf7f" }}>Connected</BigText>
            ) : (
              <BigText style={{ color: "#f20d0d" }}>Disconnected</BigText>
            )}
            <OrangeButton style={{ marginTop: 18, padding: "8px 20px" }}>
              See Events
            </OrangeButton>
          </div>
          <div style={{ marginLeft: 102 }}>
            <SmallText>Retention Window</SmallText>
            <BigText>{}</BigText>
            <SmallText style={{ marginTop: 137, marginBottom: 8 }}>
              Registration
            </SmallText>
            <BigText>{}</BigText>
            <OrangeButton style={{ marginTop: 18, padding: "8px 20px" }}>
              Unregister
            </OrangeButton>
          </div>
          <div style={{ marginLeft: 112 }}>
            <SmallText>Camera Capacity</SmallText>
            <BigText>{appliance.max_cameras}</BigText>
          </div>
        </Flex>
      </Body>
      <Footer>
        <div>
          <Link to="/device">
            <BackBtn size="large">Back</BackBtn>
          </Link>
        </div>
        <Link to="/device">
          <NextBtn
            size="large"
            onClick={() => {
              saveFunc();
            }}
          >
            Save
          </NextBtn>
        </Link>
      </Footer>
    </Wrapper>
  );
};

export default EditAppliance;
