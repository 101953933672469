import React from "react";

const Logo = (props) => {
  return (
    <svg
      width="16"
      height="8"
      viewBox="0 0 16 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.39961 1.4248L6.83294 6.85814C7.47461 7.49981 8.52461 7.49981 9.16628 6.85814L14.5996 1.4248"
        stroke="#414E62"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Logo;
