import { CheckTreePicker } from "rsuite";
import styled from "styled-components";

export default styled(CheckTreePicker)`
  .rs-picker-toggle {
    font-size: 16px;
    font-weight: 400;
    width: 250px;
    border-radius: 60px;
    display: flex;
    align-items: center;
  }
  .rs-stack-item {
    padding: 4px;
  }
  .rs-icon {
    display: none;
  }
  .rs-picker-toggle.rs-btn.rs-btn-default {
    padding-right: 6px !important;
  }
  .rs-picker-value-count {
    font-size: 10px;
    padding: 0 6px;
  }
  .rs-picker-search-bar {
    min-width: 360px !important;
    max-width: 360px !important;
    width: 360px !important;
  }
  .rs-check-tree {
    min-width: 360px !important;
    max-width: 360px !important;
    width: 360px !important;
  }
`;
