import React from "react";
import styled from "styled-components";
import { Input, Tag } from "antd";
import { Button } from "rsuite";
import EditIcon from "./Icons/Edit";
import DeactivateIcon from "./Icons/Deactivate";
import DeleteIcon from "./Icons/Delete";

const CustomDiv = styled.div`
  display: flex;
  align-items: center;
`;

const MainWrapper = styled.div`
  padding: 46px 40px;
`;

const Title = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #202b37;
`;
const UserInfo = styled.div`
  display: flex;
  align-items: flex-start;
`;
const UserButtons = styled.div`
  position: absolute;
  left: 40px;
  bottom: 50px;
`;
const Property = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #97a1af;
  margin: 36px 0 10px 0;
`;
const Value = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #344051;
  margin-bottom: 3px;
`;
const SmallValue = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #97a1af;
`;

const MyInput = styled(Input)`
  width: 200px;
  height: 40px;
  border: 1px solid #94a3b8;
  border-radius: 8px;
`;

const UserInputOne = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const ConnectStatus = styled(Tag)`
  margin-top: 4px;
  padding: 6px 20px;
  background: rgba(227, 0, 0, 0.2);
  border: 1px solid #eb5757;
  border-radius: 29px;
  font-weight: 700;
  font-size: 10px;
  letter-spacing: 0.025em;
  color: #eb5757;
  margin-bottom: 30px;
`;
const DeactivateButton = styled(Button)`
  padding: 6px 12px !important;
  gap: 6px !important;
  width: 172px !important;
  height: 36px !important;
  border-radius: 8px !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #637083 !important;
  margin-bottom: 9px !important;
`;
const DeleteButton = styled(Button)`
  padding: 6px 12px !important;
  gap: 6px !important;
  width: 146px !important;
  height: 36px !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #637083 !important;
  border-radius: 8px !important;
`;
const ChangeText = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #637083;
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 8px;
`;
const Main = () => {
  return (
    <MainWrapper>
      <UserInfo>
        <div style={{ marginRight: 40 }}>
          <Title>Configure Account</Title>
          <Property>User Type</Property>
          <Value>Admin</Value>
        </div>
        <div style={{ marginRight: 140 }}>
          <CustomDiv>
            <EditIcon /> Edit
          </CustomDiv>
          <Property>User ID</Property>
          <Value>UFG24556</Value>
        </div>
        <div style={{ paddingTop: 20, marginRight: 80 }}>
          <Property>Last Login</Property>
          <Value>03:57 PM(IST)</Value>
          <SmallValue>08.05.2023</SmallValue>
          <ConnectStatus>OFFLINE</ConnectStatus>
        </div>
        <div style={{ paddingTop: 20 }}>
          <Property>Assigned Locations</Property>
          <Value>Home, Oakland Zoo, City Hall</Value>
          <SmallValue>Full Access</SmallValue>
        </div>
      </UserInfo>
      <div>
        <div>
          <UserInputOne>
            <div>
              <Property style={{ marginBottom: 4 }}>First Name</Property>
              <MyInput value="Jill" />
            </div>
            <div>
              <Property style={{ marginBottom: 4 }}>Last Name</Property>
              <MyInput value="Jones" />
            </div>
          </UserInputOne>
          <UserInputOne>
            <div>
              <Property style={{ marginBottom: 4 }}>E-mail Address</Property>
              <MyInput value="john@email.com" />
            </div>
            <div>
              <Property style={{ marginBottom: 4 }}>Phone Number</Property>
              <MyInput value="(212)456 - 7890" />
            </div>
          </UserInputOne>
          <UserInputOne style={{ alignItems: "flex-start" }}>
            <div>
              <Property style={{ marginBottom: 4 }}>Username</Property>
              <MyInput value="JohnJones1" />
            </div>
            <div>
              <Property style={{ marginBottom: 4 }}>Password</Property>
              <MyInput type="password" value="P@ssw0rd" />
              <ChangeText>
                <EditIcon /> Change Password
              </ChangeText>
            </div>
          </UserInputOne>
        </div>
      </div>
      <UserButtons>
        <DeactivateButton appearance="subtle">
          <DeactivateIcon />
          Deactivate Account
        </DeactivateButton>
        <br />
        <DeleteButton appearance="subtle">
          <DeleteIcon />
          Delete Account
        </DeleteButton>
      </UserButtons>
    </MainWrapper>
  );
};

export default Main;
