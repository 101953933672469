import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, Modal } from "rsuite";
import CameraSelectPicker from "./../../Components/CameraSelectPicker";
import Toggle from "./../../Components/StatusToggle";
import WarnIcon from "./Icons/Warning";
import MapIcon from "./Icons/Map";
import SmallIcon from "./Icons/Small";
import MidIcon from "./Icons/Mid";
import LargeIcon from "./Icons/Large";
import { Select, Image, Input, Radio } from "antd";
import { VideoCameraOutlined, PlusCircleOutlined } from "@ant-design/icons";
import SortBySelect from "./../../Components/SortBySelect";
import axios from "axios";
import _ from "lodash";
import backendAPI from "./../../utils/backendAPI";

import "./LiveView.css";

const istOptions = {
  timeZone: "Asia/Kolkata",
  hour12: true,
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
};

const WallViewSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 5px;
    background: var(--neutral-gray-100, #f2f4f7) !important;
    color: var(--neutral-gray-600, #414e62);
    font-size: 14px;
    font-family: Lato;
    letter-spacing: -0.7px;
  }
  width: 120px;
`;

const WallSelect = styled(Select)`
  .ant-select-selector {
    background-color: #97a1af !important;
    color: white;
    font-size: 14px;
    font-weight: 600;
  }
  .ant-select-arrow {
    display: none;
  }
  .ant-select-selection-item {
    color: white !important;
    text-align: center;
    padding: 0 !important;
  }
`;

const IconButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  svg {
    stroke: ${({ view }) => (view ? "#414E62" : "#CED2DA")};
    fill: ${({ view }) => (view ? "#414E62" : "#CED2DA")};
  }
`;

const cameraData = (locations, status) => {
  const data = [
    {
      label: "All Cameras",
      value: "All Cameras",
      children: [],
    },
  ];
  let temp = [];
  let tmp = [];
  _.map(locations, (location, index) => {
    _.map(location.cameras, (camera, index) => {
      if (status === true || camera.is_overall_online === status) {
        tmp.push({
          label: (
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {camera.name}
              <Image src={camera.thumbnail} preview={false} height="30px" />
            </span>
          ),
          value: location.name + "-camera-" + index,
        });
      }
    });
    temp.push({
      value: "location-" + index,
      label: location.name,
      children: tmp,
    });
  });
  data[0].children = temp;
  return data;
};

const ModalContent = styled.div`
  padding: 55px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: left;
  flex-direction: column;
  gap: 11px;
`;

const ModalHeaderText1 = styled.div`
  letter-spacing: 0.025em;
  font-size: 24px;
  font-weight: 600;
  color: #414e62;
`;

const ModalHeaderText2 = styled.div`
  letter-spacing: 0.025em;
  color: #97a1af;
  font-size: 16px;
  font-weight: 400;
`;

const ModalDescription = styled.div`
  color: #637083;
  display: flex;
  align-items: center;
  letter-spacing: 0.025em;
  font-size: 16px;
  margin-bottom: 10px;
`;
const InputX = styled(Input)`
  border: 1px solid #97a1af;
  border-radius: 4px;
  padding: 14px;
  width: 250px;
`;

const WallSaveBtn = styled(Button)`
  background-color: #ff6600 !important;
  border-radius: 8px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  padding: 10px 16px 10px 16px !important;
  width: 150px !important;
  height: 45px !important;
  color: white !important;
`;

const WallModal = styled(Modal)`
  display: flex;
  align-items: center;
`;

const LiveView = () => {
  const [timeNow, setTimeNow] = useState("");
  const [cameraType, setCameraType] = useState(false);
  const [sortBy, setSortBy] = useState("location");
  const [locations, setLocations] = useState([]);
  const [allCamera, setAllCamera] = useState([]);
  const [viewMethod, setViewMethod] = useState(5);
  const [wallModal, setWallModal] = useState(false);
  const [wallView, setWallView] = useState("grid");

  const cameraChange = (value) => {};

  const getCameras = async () => {
    try {
      const response = await axios.get(
        `${backendAPI.address}/api/v1/locations/?withCameras=true`
      );
      setLocations(response.data);
      setAllCamera(response.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeNow(new Date().toLocaleString("en-US", istOptions));
    }, 1000);
    getCameras();
    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <>
      <div className="live-view-header">
        <span className="live-view-title">Live View</span>
        <span className="live-view-announce">
          <WarnIcon />
          <div>
            <b>Note:</b> The Live View Cameras have 4-5 seconds lag.
          </div>
        </span>
      </div>
      <div className="live-view-footer">{timeNow}(IST)</div>
      <div className="live-view-filter">
        <div className="live-view-filter-left">
          <CameraSelectPicker
            defaultExpandAll
            placeholder={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>Cameras</div>
                <div>
                  <VideoCameraOutlined />
                </div>
              </div>
            }
            data={cameraData(allCamera, cameraType)}
            onChange={cameraChange}
            style={{ marginRight: 20 }}
          />
          <Toggle
            onChange={() => setCameraType(!cameraType)}
            bgCol={cameraType}
          />
          <span className="toggle-text">Include offline Cameras</span>
        </div>
        <div className="live-view-filter-left">
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                gap: 6,
                display: "flex",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  fontWeight: 400,
                  fontSize: 14,
                  color: "#637083",
                  top: -25,
                }}
              >
                View:
              </div>
              <IconButton
                view={viewMethod === 5}
                onClick={() => setViewMethod(5)}
              >
                <SmallIcon />
              </IconButton>
              <IconButton
                view={viewMethod === 4}
                onClick={() => setViewMethod(4)}
              >
                <MidIcon />
              </IconButton>
              <IconButton
                view={viewMethod === 3}
                onClick={() => setViewMethod(3)}
              >
                <LargeIcon />
              </IconButton>
              <IconButton
                view={viewMethod === 0}
                onClick={() => setViewMethod(0)}
              >
                <MapIcon />
              </IconButton>
            </div>
            <WallSelect
              style={{ width: 140, marginLeft: 24 }}
              defaultValue="custom"
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <div
                    className="new-wall-btn"
                    onClick={() => setWallModal(true)}
                  >
                    <PlusCircleOutlined />
                    <div>&nbsp;Create New Wall</div>
                  </div>
                </>
              )}
              options={[
                { value: "all", label: "All Cameras" },
                { value: "custom", label: "Custom Wall" },
              ]}
            />
          </div>

          <div>
            <SortBySelect
              style={{ marginLeft: 150 }}
              onChange={(value) => {
                setSortBy(value);
              }}
              value={sortBy}
              options={[
                {
                  value: "location",
                  label: (
                    <span className="select-text-1">
                      Sort by: <span className="select-text-2">Location</span>
                    </span>
                  ),
                },
                {
                  value: "online",
                  label: (
                    <span className="select-text-1">
                      Sort by: <span className="select-text-2">Online</span>
                    </span>
                  ),
                },
                {
                  value: "offline",
                  label: (
                    <span className="select-text-1">
                      Sort by: <span className="select-text-2">Offline</span>
                    </span>
                  ),
                },
              ]}
            />
          </div>
          <WallModal
            open={wallModal}
            onClose={() => setWallModal(false)}
            backdrop={true}
            size="lg"
          >
            <Modal.Header></Modal.Header>
            <ModalContent>
              <ModalHeader>
                <ModalHeaderText1>Create a Custom Wall View</ModalHeaderText1>
                <ModalHeaderText2>
                  You can save a custom wall view including your choice of
                  Cameras.
                </ModalHeaderText2>
              </ModalHeader>
              <div>
                <ModalDescription>
                  Choose Cameras to include in Wall
                </ModalDescription>
                <CameraSelectPicker
                  defaultExpandAll
                  placeholder={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>Cameras</div>
                      <div>
                        <VideoCameraOutlined />
                      </div>
                    </div>
                  }
                  data={cameraData(allCamera, cameraType)}
                  onChange={cameraChange}
                />
              </div>
              <div>
                <ModalDescription>Choose a name for your Wall</ModalDescription>
                <InputX placeholder="Name your Wall" />
              </div>
              <div>
                <WallViewSelect
                  onChange={(value) => {
                    setWallView(value);
                  }}
                  value={wallView}
                  options={[
                    {
                      value: "grid",
                      label: "Grid View",
                    },
                    {
                      value: "row",
                      label: "Row View",
                    },
                    {
                      value: "column",
                      label: "Column View",
                    },
                  ]}
                />
              </div>
              <div>
                <ModalDescription>Camera preferences</ModalDescription>
                <Radio>Auto-Rotated in full view</Radio>
              </div>
            </ModalContent>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <WallSaveBtn>Save Wall</WallSaveBtn>
            </div>
          </WallModal>
        </div>
      </div>
    </>
  );
};

export default LiveView;
