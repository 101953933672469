
import React from 'react';

const Insights = (props)=> {
  return (
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M8.93347 23.6251C8.42097 23.6251 7.99597 23.2001 7.99597 22.6876V20.1001C7.99597 19.5876 8.42097 19.1626 8.93347 19.1626C9.44597 19.1626 9.87097 19.5876 9.87097 20.1001V22.6876C9.87097 23.2126 9.44597 23.6251 8.93347 23.6251Z"/>
        <path d="M15.3335 23.625C14.821 23.625 14.396 23.2 14.396 22.6875V17.5C14.396 16.9875 14.821 16.5625 15.3335 16.5625C15.846 16.5625 16.271 16.9875 16.271 17.5V22.6875C16.271 23.2125 15.846 23.625 15.3335 23.625Z"/>
        <path d="M21.7335 23.6251C21.221 23.6251 20.796 23.2001 20.796 22.6876V14.9126C20.796 14.4001 21.221 13.9751 21.7335 13.9751C22.246 13.9751 22.671 14.4001 22.671 14.9126V22.6876C22.671 23.2126 22.2585 23.6251 21.7335 23.6251Z"/>
        <path d="M8.93347 16.4748C8.50847 16.4748 8.13347 16.1873 8.02097 15.7623C7.89597 15.2623 8.19597 14.7498 8.70847 14.6248C13.3085 13.4748 17.3585 10.9623 20.446 7.37478L21.021 6.69978C21.3585 6.31228 21.946 6.26228 22.346 6.59978C22.7335 6.93728 22.7835 7.52478 22.446 7.92478L21.871 8.59978C18.5335 12.4998 14.1335 15.2123 9.15847 16.4498C9.08347 16.4748 9.00847 16.4748 8.93347 16.4748Z"/>
        <path d="M21.7335 11.9C21.221 11.9 20.796 11.475 20.796 10.9625V8.25H18.071C17.5585 8.25 17.1335 7.825 17.1335 7.3125C17.1335 6.8 17.5585 6.375 18.071 6.375H21.7335C22.246 6.375 22.671 6.8 22.671 7.3125V10.975C22.671 11.4875 22.2585 11.9 21.7335 11.9Z"/>
        <path d="M19.0835 28.4375H11.5835C4.796 28.4375 1.896 25.5375 1.896 18.75V11.25C1.896 4.4625 4.796 1.5625 11.5835 1.5625H19.0835C25.871 1.5625 28.771 4.4625 28.771 11.25V18.75C28.771 25.5375 25.871 28.4375 19.0835 28.4375ZM11.5835 3.4375C5.821 3.4375 3.771 5.4875 3.771 11.25V18.75C3.771 24.5125 5.821 26.5625 11.5835 26.5625H19.0835C24.846 26.5625 26.896 24.5125 26.896 18.75V11.25C26.896 5.4875 24.846 3.4375 19.0835 3.4375H11.5835Z"/>
    </svg>
  );
};

export default Insights;
