import React from "react";

const Map = (props) => {
  return (
    <svg
      width="30"
      height="25"
      viewBox="0 0 30 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M19.5623 22.302C19.0248 22.302 18.4873 22.2083 18.0498 22.0312L11.4873 19.2916C11.1123 19.1353 10.3748 19.1458 10.0123 19.3228L7.0623 20.7291C5.7873 21.3333 4.4748 21.4166 3.4873 20.927C2.4873 20.4478 1.9248 19.4687 1.9248 18.2395V8.1145C1.9248 7.16658 2.6748 6.09367 3.6623 5.62492L9.07481 3.04158C9.9873 2.60408 11.3748 2.57283 12.3123 2.96867L18.8748 5.70825C19.2498 5.8645 19.9748 5.84367 20.3498 5.677L23.2873 4.28117C24.5623 3.677 25.8748 3.59367 26.8623 4.08325C27.8623 4.56242 28.4248 5.54158 28.4248 6.77075V16.9062C28.4248 17.8541 27.6748 18.927 26.6873 19.3958L21.2748 21.9791C20.7998 22.1874 20.1748 22.302 19.5623 22.302ZM10.7998 17.6249C11.3373 17.6249 11.8748 17.7187 12.3123 17.8958L18.8748 20.6353C19.2498 20.7916 19.9748 20.7708 20.3498 20.6041L25.7623 18.0208C26.1623 17.8333 26.5498 17.2708 26.5498 16.8958V6.76033C26.5498 6.10408 26.3248 5.6145 25.9123 5.427C25.5123 5.2395 24.8873 5.31242 24.2123 5.63533L21.2748 7.03117C20.3623 7.46867 18.9748 7.49992 18.0373 7.10408L11.4748 4.3645C11.0998 4.20825 10.3748 4.22908 9.9998 4.39575L4.5873 6.97908C4.1873 7.16658 3.7998 7.72908 3.7998 8.1145V18.2499C3.7998 18.9062 4.0248 19.3958 4.4248 19.5833C4.8248 19.7812 5.4498 19.6978 6.1373 19.3749L9.07481 17.9791C9.5623 17.7395 10.1873 17.6249 10.7998 17.6249Z" />
      <path d="M10.6992 18.4894C10.1867 18.4894 9.76172 18.1353 9.76172 17.7082V4.1665C9.76172 3.73942 10.1867 3.38525 10.6992 3.38525C11.2117 3.38525 11.6367 3.73942 11.6367 4.1665V17.7082C11.6367 18.1353 11.2117 18.4894 10.6992 18.4894Z" />
      <path d="M19.6631 21.6147C19.1506 21.6147 18.7256 21.2606 18.7256 20.8335V6.896C18.7256 6.46891 19.1506 6.11475 19.6631 6.11475C20.1756 6.11475 20.6006 6.46891 20.6006 6.896V20.8335C20.6006 21.2606 20.1756 21.6147 19.6631 21.6147Z" />
    </svg>
  );
};

export default Map;
