import React, { useEffect, useState } from "react";

import CameraTable from "./CameraTable";
import "./index.css";

const LivePage = (props) => {
  const [animation, setAnimation] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimation(true);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className={`camera-table-view ${animation ? "active" : ""}`}>
      <CameraTable
        cameras={props.cameras}
        cameraType={props.cameraType}
        sortBy={props.sortBy}
      />
    </div>
  );
};

export default LivePage;
