import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Input, Select } from "antd";
import { Button } from "rsuite";
import axios from "axios";
import _ from "lodash";
import backendAPI from "./../../utils/backendAPI";
import { set } from "lodash";

const Wrapper = styled.div`
  padding: 144px 40px 0 40px;
`;

const Header = styled.div`
  color: var(--neutral-gray-700, #344051);
  font-size: 24px;
  font-family: Lato;
  font-weight: 700;
  letter-spacing: 0.6px;
  padding-left: 40px;
  padding-bottom: 23px;
  border-bottom: 1px solid #e4e7ec;
`;

const Text = styled.div`
  color: var(--neutral-gray-600, #414e62);
  font-size: 16px;
  font-family: Lato;
  font-weight: 700;
  letter-spacing: 0.4px;
`;

const Body = styled.div`
  padding-top: 40px;
  padding-left: 40px;
`;
const Footer = styled.div`
  padding: 194px 35px 83px 35px;
  justify-content: space-between;
  display: flex;
  align-items: center;
`;

const LocationSelect = styled(Select)`
  width: 530px;
  .ant-select-selector {
    height: 40px !important;
  }
  .ant-select-selection-item,
  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }
`;

const NumberInput = styled(Input)`
  width: 342px;
  margin-right: 23px;
  height: 40px;
`;
const NameInput = styled(Input)`
  width: 342px;
  height: 40px;
`;
const CheckButton = styled(Button)`
  border-radius: 8px !important;
  border: 1px solid var(--neutral-gray-600, #414e62) !important;
  width: 115px !important;
  height: 42px !important;
  padding: 16px !important;
  color: var(--neutral-900, #0f172a) !important;
  font-size: 16px !important;
  font-family: Lato !important;
  font-weight: 600 !important;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const BackBtn = styled(Button)`
  border: 1px solid #414e62 !important;
  border-radius: 8px !important;
  width: 115px !important;
  height: 56px !important;
  padding: 16px !important;
  color: var(--neutral-900, #0f172a) !important;
  font-size: 16px !important;
  font-family: Lato !important;
  font-weight: 600 !important;
  background-color: white !important;
`;

const NextBtn = styled(Button)`
  background: ${({ disabled }) =>
    disabled ? "#F1F5F9 !important" : "#40bf7f !important"};
  border: 1px solid !important;

  border-color: ${({ disabled }) =>
    disabled ? "#F1F5F9 !important" : "#40bf7f !important"};
  border-radius: 8px !important;
  width: 131px !important;
  height: 56px !important;
  padding: 16px !important;
  color: ${({ disabled }) =>
    disabled ? "#94A3B8 !important" : "#fff !important"};
`;

const getLocationName = (locations) => {
  const temp = [];
  _.map(locations, (location) => {
    temp.push({
      label: location.name,
      value: location.name,
    });
  });
  return temp;
};

const AddAppliance = () => {
  const [locations, setLocations] = useState([]);
  const [numberCheck, setNumberCheck] = useState(false);
  useEffect(() => {
    const loadLocations = async () => {
      try {
        const response = await axios.get(
          `${backendAPI.address}/api/v1/locations/`
        );
        setLocations(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    loadLocations();
    return;
  }, []);

  return (
    <Wrapper>
      <Header>Register new Appliance</Header>
      <Body>
        <Text style={{ marginBottom: 14 }}>Serial Number*</Text>
        <Flex>
          <NumberInput placeholder="Enter serial number from your appliance" />
          <CheckButton appearance="subtle">Check</CheckButton>
        </Flex>
        <Text style={{ marginBottom: 23, marginTop: 35 }}>
          Select Location*
        </Text>
        <LocationSelect
          showSearch
          options={getLocationName(locations)}
          placeholder="Type to search by location name"
        />
        <Text style={{ marginBottom: 17, marginTop: 42 }}>Name (Optional)</Text>
        <NameInput placeholder="Enter a friendly name" />
      </Body>
      <Footer>
        <div>
          <Link to="/device">
            <BackBtn size="large">Back</BackBtn>
          </Link>
        </div>
        {numberCheck ? (
          <Link to="/device">
            <NextBtn size="large">Save</NextBtn>
          </Link>
        ) : (
          <NextBtn size="large" disabled={true}>
            Save
          </NextBtn>
        )}
      </Footer>
    </Wrapper>
  );
};

export default AddAppliance;
