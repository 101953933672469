import React from 'react';

const Live = props => (
  <svg
    width="31"
    height="30"
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.75 27.1875H20.5C19.9875 27.1875 19.5625 26.7625 19.5625 26.25C19.5625 25.7375 19.9875 25.3125 20.5 25.3125H21.75C24.975 25.3125 27.0625 23.225 27.0625 20V10C27.0625 6.775 24.975 4.6875 21.75 4.6875H9.25C6.025 4.6875 3.9375 6.775 3.9375 10V11.25C3.9375 11.7625 3.5125 12.1875 3 12.1875C2.4875 12.1875 2.0625 11.7625 2.0625 11.25V10C2.0625 5.7 4.95 2.8125 9.25 2.8125H21.75C26.05 2.8125 28.9375 5.7 28.9375 10V20C28.9375 24.3 26.05 27.1875 21.75 27.1875Z"
    />
    <path
      d="M15.8625 26.3249C15.4 26.3249 15 25.9749 14.9375 25.5124C14.25 20.1624 10.35 16.2499 4.98747 15.5624C4.47497 15.4999 4.11248 15.0249 4.17498 14.5124C4.23748 13.9999 4.71247 13.6374 5.22497 13.6999C11.45 14.4999 16 19.0374 16.7875 25.2624C16.85 25.7749 16.4875 26.2499 15.975 26.3124C15.95 26.3249 15.9 26.3249 15.8625 26.3249Z"
    />
    <path
      d="M11.6621 27.6626C11.1996 27.6626 10.7996 27.3126 10.7371 26.8501C10.2496 23.0376 7.4621 20.2501 3.6496 19.7626C3.1371 19.7001 2.77458 19.2251 2.83708 18.7126C2.89958 18.2001 3.3746 17.8376 3.8871 17.9001C8.5746 18.5001 11.9871 21.9251 12.5871 26.6001C12.6496 27.1126 12.2871 27.5876 11.7746 27.6501C11.7371 27.6626 11.6996 27.6626 11.6621 27.6626Z"
    />
    <path
      d="M6.91278 28.4626C6.45028 28.4626 6.05027 28.1126 5.98777 27.6501C5.77527 25.9626 4.53778 24.7251 2.85028 24.5126C2.33778 24.4501 1.97527 23.9751 2.03777 23.4626C2.10027 22.9501 2.57528 22.5876 3.08778 22.6501C5.61278 22.9751 7.52528 24.8876 7.85028 27.4126C7.91278 27.9251 7.55027 28.4001 7.03777 28.4626C7.00027 28.4626 6.96278 28.4626 6.91278 28.4626Z"
    />
  </svg>
);

export default Live;
