import React from "react";

const Group17 = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 13.75C11.59 13.75 11.25 13.41 11.25 13V7.75C11.25 7.34 11.59 7 12 7C12.41 7 12.75 7.34 12.75 7.75V13C12.75 13.41 12.41 13.75 12 13.75Z"
        fill="#FFC34C"
      />
      <path
        d="M12 17.2492C11.73 17.2492 11.48 17.1493 11.29 16.9593C11.2 16.8593 11.13 16.7492 11.07 16.6292C11.02 16.5092 11 16.3792 11 16.2492C11 15.9892 11.11 15.7292 11.29 15.5392C11.66 15.1692 12.34 15.1692 12.71 15.5392C12.89 15.7292 13 15.9892 13 16.2492C13 16.3792 12.97 16.5092 12.92 16.6292C12.87 16.7492 12.8 16.8593 12.71 16.9593C12.52 17.1493 12.27 17.2492 12 17.2492Z"
        fill="#FFC34C"
      />
      <path
        d="M12.0002 22.7491C11.3302 22.7491 10.6502 22.5791 10.0502 22.2291L4.11017 18.7991C2.91017 18.0991 2.16016 16.8091 2.16016 15.4191V8.57914C2.16016 7.18914 2.91017 5.89913 4.11017 5.19913L10.0502 1.76914C11.2502 1.06914 12.7402 1.06914 13.9502 1.76914L19.8902 5.19913C21.0902 5.89913 21.8402 7.18914 21.8402 8.57914V15.4191C21.8402 16.8091 21.0902 18.0991 19.8902 18.7991L13.9502 22.2291C13.3502 22.5791 12.6702 22.7491 12.0002 22.7491ZM12.0002 2.74912C11.5902 2.74912 11.1702 2.85913 10.8002 3.06913L4.86017 6.49912C4.12017 6.92912 3.66016 7.71914 3.66016 8.57914V15.4191C3.66016 16.2691 4.12017 17.0691 4.86017 17.4991L10.8002 20.9291C11.5402 21.3591 12.4602 21.3591 13.1902 20.9291L19.1302 17.4991C19.8702 17.0691 20.3302 16.2791 20.3302 15.4191V8.57914C20.3302 7.72914 19.8702 6.92912 19.1302 6.49912L13.1902 3.06913C12.8302 2.85913 12.4102 2.74912 12.0002 2.74912Z"
        fill="#FFC34C"
      />
    </svg>
  );
};

export default Group17;
