import React from "react";
import { Tag, Image } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import RightArrow from "../Icons/RightArrow";
import _ from "lodash";
import "./index.css";

const MainTable = styled.div`
  table {
    width: 100%;
    text-align: left;
    border-collapse: separate;
    border-spacing: 25px;
  }
  thead {
    width: 100%;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #414e62;
    opacity: 0.4;
  }
  tbody {
    width: 100%;
    tr {
      width: 100%;
      box-shadow: 1px 1px 6px -4px;
      height: 110px;
    }
  }
`;

const NameText = styled.div`
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #202b37;
`;
const RoleText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #202b37;
  opacity: 0.4;
`;

const MoreText = styled.div`
  font-weight: 700;
  font-size: 10px;
  line-height: 100%;
  letter-spacing: 0.025em;
  color: #828282;
`;

const NormalText = styled.div`
  color: var(--neutral-gray-700, #344051);
  font-size: 16px;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const Active = styled(Tag)`
  text-align: center;
  font-weight: 700;
  font-size: 10px;
  width: 120px;
  line-height: 100%;
  letter-spacing: 0.025em;
  color: #40bf7f;
  background: rgba(111, 207, 151, 0.25);
  border: 1px solid #40bf7f;
  border-radius: 29px;
  padding: 12px 22px;
`;

const Offline = styled(Tag)`
  text-align: center;
  width: 120px;
  background: rgba(227, 0, 0, 0.2);
  border: 1px solid #f20d0d;
  border-radius: 29px;
  font-weight: 700;
  font-size: 10px;
  line-height: 100%;
  letter-spacing: 0.025em;
  padding: 12px 22px;
  color: #f20d0d;
  margin-bottom: 8px;
`;

const convertData = (cameras, cameraType, sortBy) => {
  if (sortBy) {
    if (cameraType) {
      return cameras.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    } else {
      return _.filter(cameras, (camera) => camera.lan_connectivity_status).sort(
        (a, b) => {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        }
      );
    }
  } else {
    if (cameraType) {
      return cameras.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
    } else {
      return _.filter(cameras, (camera) => camera.lan_connectivity_status).sort(
        (a, b) => {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();

          if (nameA < nameB) {
            return 1;
          }
          if (nameA > nameB) {
            return -1;
          }
          return 0;
        }
      );
    }
  }
};

const Camera = (props) => {
  return (
    <div className="camera">
      <MainTable>
        <table>
          <thead>
            <tr>
              <th style={{ paddingLeft: 44 }}>Camera Name</th>
              <th>IP Address</th>
              <th>Location</th>
              <th>OEM</th>
              <th>Connection Status</th>
              <th>Connected Appliance</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {_.map(
              convertData(props.cameras, props.cameraType, props.sortBy),
              (dat, id) => (
                <tr>
                  <td style={{ paddingLeft: 44 }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Image
                        preview={false}
                        src={dat.thumbnail}
                        width="100px"
                      />
                      <div style={{ marginLeft: 12 }}>
                        <NameText>{dat.name}</NameText>
                        <RoleText>Camera#{dat.id}</RoleText>
                      </div>
                    </div>
                  </td>
                  <td>
                    <NormalText>{dat.lan_ip_address}</NormalText>
                    <RoleText>{dat.mac_address}</RoleText>
                  </td>
                  <td>
                    <NormalText style={{ marginBottom: 9 }}>
                      {dat.appliance_details.location.name}
                    </NormalText>
                    <RoleText>{dat.expiry_date}</RoleText>
                  </td>
                  <td>
                    <NormalText>{dat.make}</NormalText>
                    <RoleText>{dat.model}</RoleText>
                  </td>
                  <td>
                    {dat.lan_connectivity_status ? (
                      <Active>ONLINE</Active>
                    ) : (
                      <div>
                        <Offline>OFFLINE</Offline>
                      </div>
                    )}
                  </td>
                  <td>
                    <NormalText>{dat.appliance_camera_id}</NormalText>
                  </td>
                  <td style={{ paddingRight: 16 }}>
                    <div
                      style={{
                        gap: 4,
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <MoreText>EDIT</MoreText>
                      <RightArrow />
                    </div>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </MainTable>
    </div>
  );
};

export default Camera;
