import React from "react";

const Warning = (props) => {
  return (
    <svg
      width="11"
      height="18"
      viewBox="0 0 11 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.59832 17.3334C2.16926 17.3334 1.7402 17.1701 1.41337 16.8451C0.758043 16.1934 0.758043 15.1401 1.41337 14.4884L6.95265 8.98009L1.62287 3.49176C0.980955 2.82842 0.999391 1.77342 1.66478 1.13509C2.33184 0.496756 3.39276 0.515089 4.03468 1.17509L10.5075 7.84176C11.1427 8.49676 11.1343 9.53509 10.4874 10.1784L3.78328 16.8451C3.45645 17.1701 3.02739 17.3334 2.59832 17.3334Z"
        fill="#202B37"
      />
    </svg>
  );
};

export default Warning;
