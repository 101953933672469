import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Avatar, Dropdown } from "antd";
import { useNavigate } from "react-router-dom";
import backendAPI from "../../utils/backendAPI";
import axios from "axios";
import DownArrow from "./Icons/DownArrow";

const MyLink = styled(Link)`
  text-decoration: none !important;
`;

const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

const AvatarI = () => {
  const navigate = useNavigate();
  const logout = async () => {
    try {
      const response = await axios.post(
        `${backendAPI.address}/rest-auth/logout/`,
        {}
      );
      if (localStorage.getItem("user-token")) {
        localStorage.clear();
      }
      window.location.reload(false);
      setTimeout(() => {
        navigate("/");
        // setIsLoading(false);
      }, 1000);
    } catch (err) {
      console.log(err);
    }
  };
  const items = [
    {
      label: <MyLink to="/userManage">User Management</MyLink>,
      key: "0",
    },
    {
      label: <MyLink to="/locationManagement">Location Management</MyLink>,
      key: "1",
    },
    {
      label: <MyLink to="/settings">Settings</MyLink>,
      type: "2",
    },
    {
      label: <div onClick={logout}>Logout</div>,
      key: "3",
    },
  ];
  return (
    <Dropdown menu={{ items }} trigger={["click"]}>
      <AvatarWrapper>
        <Avatar src="./Icons/Avatar.png" />
        <div>
          <DownArrow />
        </div>
      </AvatarWrapper>
    </Dropdown>
  );
};

export default AvatarI;
