import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { Input, Select, Tag } from "antd";
import { Button } from "rsuite";
import axios from "axios";
import _ from "lodash";
import backendAPI from "./../../utils/backendAPI";
import WarnIcon from "./Icons/Warn";

const Wrapper = styled.div`
  padding: 144px 40px 0 40px;
  position: relative;
  height: 100vh;
`;

const Header = styled.div`
  position: relative;
  color: var(--neutral-gray-700, #344051);
  font-size: 24px;
  font-family: Lato;
  font-weight: 700;
  letter-spacing: 0.6px;
  padding-left: 40px;
  padding-bottom: 23px;
  border-bottom: 1px solid #e4e7ec;
`;

const Body = styled.div`
  padding-top: 50px;
  padding-left: 40px;
`;
const Footer = styled.div`
  padding: 0 35px;
  justify-content: space-between;
  display: flex;
  position: absolute;
  align-items: center;
  bottom: 135px;
  width: calc(100vw - 80px);
`;

const SmallLetter = styled.div`
  color: var(--neutral-gray-400, #97a1af);
  font-size: 14px;
  font-family: Lato;
  font-weight: 500;
`;

const StepImage = styled.div`
  background-image: ${({ step }) => `url('./image/step/${step}.png')`};
  background-position: center;
  background-size: cover;
  width: 517px;
  height: 32px;
`;

const StepCover = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: calc(100vw - 150px);
  top: 0px;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const BackBtn = styled(Button)`
  border: 1px solid #414e62 !important;
  border-radius: 8px !important;
  width: 115px !important;
  height: 56px !important;
  padding: 16px !important;
  color: var(--neutral-900, #0f172a) !important;
  font-size: 16px !important;
  font-family: Lato !important;
  font-weight: 600 !important;
  background-color: white !important;
`;

const NextBtn = styled(Button)`
  background: ${({ disabled }) =>
    disabled ? "#F1F5F9 !important" : "#40bf7f !important"};
  border: 1px solid !important;

  border-color: ${({ disabled }) =>
    disabled ? "#F1F5F9 !important" : "#40bf7f !important"};
  border-radius: 8px !important;
  width: 131px !important;
  height: 56px !important;
  padding: 16px !important;
  color: ${({ disabled }) =>
    disabled ? "#94A3B8 !important" : "#fff !important"};
`;

const ApplianceSelect = styled(Select)`
  width: 533px;
  .ant-select-selector {
    height: 40px !important;
  }
  .ant-select-selection-item,
  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }
  margin-bottom: 200px;
`;

const StatusTag = styled(Tag)`
  margin: 0 45px 0 30px;
  border-radius: 29px;
  text-align: center;
  padding: 2px 0;
  width: 80px;
  color: ${({ status }) => (status ? "#40bf7f" : "#f20d0d")};
  background-color: ${({ status }) =>
    status ? "rgba(111, 207, 151, 0.25)" : "rgba(227, 0, 0, 0.2)"};
  border: 1px solid;
  border-color: ${({ status }) => (status ? "#40bf7f" : "#f20d0d")};
`;

const convertData = (appliances) => {
  let temp = [];
  _.map(appliances, (appliance) => {
    temp.push({
      label:
        "Appliance#" +
        appliance.id +
        " (" +
        appliance.name +
        ") - Location: " +
        appliance.location.name,
      value:
        "Appliance#" +
        appliance.id +
        " (" +
        appliance.name +
        ") - Location: " +
        appliance.location.name,
    });
  });
  return temp;
};

const AddCamera = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [appliance, setAppliance] = useState();
  const [appliances, setAppliances] = useState([]);
  const getStatus = () => {
    const id = _.head(_.split(appliance, "#")[1], 0);
    console.log(id);
    const temp = _.filter(appliances, (app) => app.id.toString() === id);
    return _.get(temp[0], "connection_status");
  };
  const loadAppliance = async () => {
    try {
      const response = await axios.get(
        `${backendAPI.address}/api/v1/appliances/`
      );
      setAppliances(response.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    loadAppliance();
  }, []);

  const backFunc = () => {
    if (step === 0) {
      navigate("/device");
    } else {
      setStep(step - 1);
    }
  };

  const nextFunc = () => {
    if (step === 3) {
    } else {
      setStep(step + 1);
    }
  };

  return (
    <Wrapper>
      <Header>
        Add new Cameras
        <StepCover>
          <StepImage step={step} />
        </StepCover>
      </Header>
      <Body style={{ display: `${step === 0 ? "block" : "none"}` }}>
        <SmallLetter style={{ marginBottom: 14 }}>
          Select an appliance
        </SmallLetter>
        <ApplianceSelect
          value={appliance}
          onChange={(value) => {
            setAppliance(value);
          }}
          showSearch
          options={convertData(appliances)}
          placeholder="Type to search by location name or appliance name"
        />
        <Flex style={{ display: `${appliance ? "flex" : "none"}` }}>
          <SmallLetter>Appliance Status:</SmallLetter>
          <StatusTag status={getStatus()}>
            {getStatus() ? "Online" : "Offline"}
          </StatusTag>
          <div
            style={{
              display: `${getStatus() ? "none" : "flex"}`,
              alignItems: "center",
            }}
          >
            <WarnIcon />
            <SmallLetter
              style={{
                marginLeft: 8,
              }}
            >
              <b>Note: </b>The Appliance must be online to proceed to next step
            </SmallLetter>
          </div>
        </Flex>
      </Body>
      <Footer>
        <BackBtn onClick={() => backFunc()} size="large">
          Back
        </BackBtn>
        <NextBtn
          disabled={!getStatus()}
          onClick={() => nextFunc()}
          size="large"
        >
          {step === 3 ? "Done" : "Next"}
        </NextBtn>
      </Footer>
    </Wrapper>
  );
};

export default AddCamera;
