
import React from 'react';

const Search = (props)=> {
  return (
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M14.25 25.9375C7.525 25.9375 2.0625 20.475 2.0625 13.75C2.0625 7.025 7.525 1.5625 14.25 1.5625C20.975 1.5625 26.4375 7.025 26.4375 13.75C26.4375 20.475 20.975 25.9375 14.25 25.9375ZM14.25 3.4375C8.5625 3.4375 3.9375 8.0625 3.9375 13.75C3.9375 19.4375 8.5625 24.0625 14.25 24.0625C19.9375 24.0625 24.5625 19.4375 24.5625 13.75C24.5625 8.0625 19.9375 3.4375 14.25 3.4375Z"/>
        <path d="M25.7004 28.4875C25.6004 28.4875 25.5004 28.475 25.4129 28.4625C24.8254 28.3875 23.7629 27.9875 23.1629 26.2C22.8504 25.2625 22.9629 24.325 23.4754 23.6125C23.9879 22.9 24.8504 22.5 25.8379 22.5C27.1129 22.5 28.1129 22.9875 28.5629 23.85C29.0129 24.7125 28.8879 25.8125 28.1754 26.875C27.2879 28.2125 26.3254 28.4875 25.7004 28.4875ZM24.9504 25.6125C25.1629 26.2625 25.4629 26.5875 25.6629 26.6125C25.8629 26.6375 26.2379 26.4 26.6254 25.8375C26.9879 25.3 27.0129 24.9125 26.9254 24.7375C26.8379 24.5625 26.4879 24.375 25.8379 24.375C25.4504 24.375 25.1629 24.5 25.0004 24.7125C24.8504 24.925 24.8254 25.25 24.9504 25.6125Z"/>
    </svg>
  );
};

export default Search;
