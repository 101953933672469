import React, { useEffect, useState } from "react";
import CreateAlert from "./CreateAlert";
import "./index.css";

const CreateAlertAnimation = () => {
  const [animation, setAnimation] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimation(true);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className={`create-alert-view ${animation ? "active" : ""}`}>
      <CreateAlert />
    </div>
  );
};

export default CreateAlertAnimation;
