import React from "react";

const Devices = (props) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 4.375C0 3.38044 0.395088 2.42661 1.09835 1.72335C1.80161 1.02009 2.75544 0.625 3.75 0.625H17.8125C18.722 0.624893 19.6006 0.955361 20.2846 1.55486C20.9687 2.15437 21.4115 2.98204 21.5306 3.88375L27.3619 1.2925C27.6473 1.16532 27.96 1.11149 28.2715 1.13591C28.583 1.16033 28.8835 1.26221 29.1456 1.4323C29.4078 1.6024 29.6232 1.83531 29.7724 2.10986C29.9216 2.38441 29.9999 2.6919 30 3.00437V16.9956C29.9997 17.3079 29.9215 17.6151 29.7724 17.8894C29.6233 18.1637 29.408 18.3965 29.1461 18.5665C28.8843 18.7366 28.5841 18.8385 28.2728 18.8631C27.9616 18.8878 27.6491 18.8343 27.3638 18.7075L21.5306 16.1163C21.4115 17.018 20.9687 17.8456 20.2846 18.4451C19.6006 19.0446 18.722 19.3751 17.8125 19.375H3.75C2.75544 19.375 1.80161 18.9799 1.09835 18.2766C0.395088 17.5734 0 16.6196 0 15.625V4.375ZM21.5625 14.0781L28.125 16.9956V3.00437L21.5625 5.92187V14.0781ZM3.75 2.5C3.25272 2.5 2.77581 2.69754 2.42417 3.04918C2.07254 3.40081 1.875 3.87772 1.875 4.375V15.625C1.875 16.1223 2.07254 16.5992 2.42417 16.9508C2.77581 17.3025 3.25272 17.5 3.75 17.5H17.8125C18.3098 17.5 18.7867 17.3025 19.1383 16.9508C19.49 16.5992 19.6875 16.1223 19.6875 15.625V4.375C19.6875 3.87772 19.49 3.40081 19.1383 3.04918C18.7867 2.69754 18.3098 2.5 17.8125 2.5H3.75Z"
      />
    </svg>
  );
};

export default Devices;
