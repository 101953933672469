import React, { useEffect, useState } from "react";

import Header from "./Header";
import Main from "./Main";
import "./index.css";

const SettingsPage = () => {
  const [animation, setAnimation] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimation(true);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className={`settings-view ${animation ? "active" : ""}`}>
      <Header />
      <Main />
    </div>
  );
};

export default SettingsPage;
