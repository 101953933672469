import React from "react";

const Warning = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5001 5.60742C17.4834 5.60742 17.4584 5.60742 17.4334 5.60742C13.0251 5.16575 8.62505 4.99908 4.26672 5.44075L2.56672 5.60742C2.21672 5.64075 1.90839 5.39075 1.87505 5.04075C1.84172 4.69075 2.09172 4.39075 2.43339 4.35742L4.13339 4.19075C8.56672 3.74075 13.0584 3.91575 17.5584 4.35742C17.9001 4.39075 18.1501 4.69908 18.1167 5.04075C18.0917 5.36575 17.8167 5.60742 17.5001 5.60742Z"
        fill="#637083"
      />
      <path
        d="M7.08363 4.76602C7.05029 4.76602 7.01696 4.76602 6.97529 4.75768C6.64196 4.69935 6.40863 4.37435 6.46696 4.04102L6.65029 2.94935C6.78363 2.14935 6.96696 1.04102 8.90863 1.04102H11.092C13.042 1.04102 13.2253 2.19102 13.3503 2.95768L13.5336 4.04102C13.592 4.38268 13.3586 4.70768 13.0253 4.75768C12.6836 4.81602 12.3586 4.58268 12.3086 4.24935L12.1253 3.16602C12.0086 2.44102 11.9836 2.29935 11.1003 2.29935H8.91696C8.03363 2.29935 8.01696 2.41602 7.89196 3.15768L7.70029 4.24102C7.65029 4.54935 7.38363 4.76602 7.08363 4.76602Z"
        fill="#637083"
      />
      <path
        d="M12.675 18.9577H7.325C4.41666 18.9577 4.3 17.3493 4.20833 16.0493L3.66666 7.65766C3.64166 7.316 3.90833 7.016 4.25 6.991C4.6 6.97433 4.89166 7.23266 4.91666 7.57433L5.45833 15.966C5.55 17.2327 5.58333 17.7077 7.325 17.7077H12.675C14.425 17.7077 14.4583 17.2327 14.5417 15.966L15.0833 7.57433C15.1083 7.23266 15.4083 6.97433 15.75 6.991C16.0917 7.016 16.3583 7.30766 16.3333 7.65766L15.7917 16.0493C15.7 17.3493 15.5833 18.9577 12.675 18.9577Z"
        fill="#637083"
      />
      <path
        d="M11.3834 14.375H8.6084C8.26673 14.375 7.9834 14.0917 7.9834 13.75C7.9834 13.4083 8.26673 13.125 8.6084 13.125H11.3834C11.7251 13.125 12.0084 13.4083 12.0084 13.75C12.0084 14.0917 11.7251 14.375 11.3834 14.375Z"
        fill="#292D32"
      />
      <path
        d="M12.0837 11.041H7.91699C7.57533 11.041 7.29199 10.7577 7.29199 10.416C7.29199 10.0743 7.57533 9.79102 7.91699 9.79102H12.0837C12.4253 9.79102 12.7087 10.0743 12.7087 10.416C12.7087 10.7577 12.4253 11.041 12.0837 11.041Z"
        fill="#292D32"
      />
    </svg>
  );
};

export default Warning;
