import React, { useEffect, useState } from "react";

import AddCamera from "./AddCamera";
import "./index.css";

const AddDevice = () => {
  const [animation, setAnimation] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimation(true);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className={`add-camera-view ${animation ? "active" : ""}`}>
      <AddCamera />
    </div>
  );
};

export default AddDevice;
