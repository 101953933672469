import React from "react";
import { Tag } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import RightArrow from "../Icons/RightArrow";
import _ from "lodash";
import "./index.css";

const MainTable = styled.div`
  table {
    width: 100%;
    text-align: left;
    border-collapse: separate;
    border-spacing: 25px;
  }
  thead {
    width: 100%;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #414e62;
    opacity: 0.4;
  }
  tbody {
    width: 100%;
    tr {
      width: 100%;
      box-shadow: 1px 1px 6px -4px;
      height: 110px;
    }
  }
`;

const NameText = styled.div`
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #202b37;
`;
const RoleText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #202b37;
  opacity: 0.4;
`;

const MoreText = styled.div`
  font-weight: 700;
  font-size: 10px;
  line-height: 100%;
  letter-spacing: 0.025em;
  color: #828282;
`;

const NormalText = styled.div`
  color: var(--neutral-gray-700, #344051);
  font-size: 16px;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const Active = styled(Tag)`
  text-align: center;
  font-weight: 700;
  font-size: 10px;
  width: 120px;
  line-height: 100%;
  letter-spacing: 0.025em;
  color: #40bf7f;
  background: rgba(111, 207, 151, 0.25);
  border: 1px solid #40bf7f;
  border-radius: 29px;
  padding: 12px 22px;
`;

const Offline = styled(Tag)`
  text-align: center;
  width: 120px;
  background: rgba(227, 0, 0, 0.2);
  border: 1px solid #f20d0d;
  border-radius: 29px;
  font-weight: 700;
  font-size: 10px;
  line-height: 100%;
  letter-spacing: 0.025em;
  padding: 12px 22px;
  color: #f20d0d;
  margin-bottom: 8px;
`;

const convertData = (appliances, cameraType, searchQuery, sortBy) => {
  if (sortBy) {
    if (cameraType === true) {
      return _.filter(appliances, (appliance) =>
        appliance.location.name
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      ).sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    } else {
      const data = _.filter(
        appliances,
        (appliance) => appliance.connection_status
      );
      return _.filter(data, (appliance) =>
        appliance.location.name
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      ).sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    }
  } else {
    if (cameraType === true) {
      return _.filter(appliances, (appliance) =>
        appliance.location.name
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      ).sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
    } else {
      const data = _.filter(
        appliances,
        (appliance) => appliance.connection_status
      );
      return _.filter(data, (appliance) =>
        appliance.location.name
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      ).sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
    }
  }
};

const Appliance = (props) => {
  return (
    <div className="appliance">
      <MainTable>
        <table>
          <thead>
            <tr>
              <th style={{ paddingLeft: 44 }}>Appliance Name</th>
              <th>Status</th>
              <th>Serial No</th>
              <th>License Status</th>
              <th>Connection Status</th>
              <th>Connected Cameras</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {_.map(
              convertData(
                props.appliances,
                props.cameraType,
                props.searchQuery,
                props.sortBy
              ),
              (dat, index) => (
                <tr>
                  <td style={{ paddingLeft: 44 }}>
                    <NameText>{dat.name}</NameText>
                    <RoleText>This is</RoleText>
                  </td>
                  <td>
                    {dat.registration_status ? (
                      <Active>REGISTERED</Active>
                    ) : (
                      <div>
                        <Offline>UNREGISTERED</Offline>
                      </div>
                    )}
                  </td>
                  <td>
                    <NormalText>{dat.serial_number}</NormalText>
                  </td>
                  <td>
                    <NormalText style={{ marginBottom: 9 }}>
                      {dat.license_key}
                    </NormalText>
                    <RoleText>
                      Valid Up to
                      <br />
                      {dat.expiry_date}
                    </RoleText>
                  </td>
                  <td>
                    {dat.connection_status ? (
                      <Active>ONLINE</Active>
                    ) : (
                      <div>
                        <Offline>OFFLINE</Offline>
                      </div>
                    )}
                  </td>
                  <td>
                    <NormalText>{dat.camera_count} Cameras</NormalText>
                    <RoleText>
                      {dat.camera_count}/{dat.max_cameras}
                    </RoleText>
                  </td>
                  <td style={{ paddingRight: 16 }}>
                    <div
                      style={{
                        gap: 4,
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <Link
                        style={{ textDecoration: "none" }}
                        to={`/editAppliance/${_.get(dat, "id")}`}
                      >
                        <MoreText>EDIT</MoreText>
                      </Link>
                      <RightArrow />
                    </div>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </MainTable>
    </div>
  );
};

export default Appliance;
