import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Input, Checkbox, notification } from "antd";
import { Button } from "rsuite";
import axios from "axios";
import backendAPI from "./../../utils/backendAPI";
import myNotification from "../../utils/notification";

import "./style/ImageWithText.css";

const ImageWithText = () => {
  const navigate = useNavigate();

  const [isSmall, setIsSmall] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const signIn = async (username, password) => {
    try {
      const response = await axios.post(
        `${backendAPI.address}/rest-auth/login/`,
        {
          username: username,
          email: username,
          password: password,
        }
      );
      if (response.status === 200) {
        const token = response.data.key;
        localStorage.setItem("user-token", token);
        window.location.reload(false);
        setTimeout(() => {
          navigate("/");
          // setIsLoading(false);
        }, 1000);
      }
      myNotification("Login Success", "success");
    } catch (err) {
      myNotification(Object.values(err.response.data)[0][0], "warning");
    }
  };

  const forgotPassword = async (username) => {
    try {
      const response = await axios.post(
        `${backendAPI.address}/rest-auth/password/reset/`,
        {
          email: username,
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setIsSmall(true);
    }, 1000); // Adjust the delay before the animation starts (in milliseconds)

    const timer2 = setTimeout(() => {
      setShowInput(true);
    }, 4000); // Adjust the delay before the second animation starts (in milliseconds)

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, []);

  return (
    <div className={`container ${isSmall ? "small" : ""}`}>
      <img
        src="./image/logo/logo.svg"
        alt="Your Image"
        className={`image ${isSmall ? "small" : ""}`}
      />
      <p className={`text ${isSmall ? "small" : ""}`}>
        Fastest Search Engine for CCTV Footages
      </p>
      <div className={`input-section ${showInput ? "visible" : ""}`}>
        <p className="sign-in-title">Sign In</p>
        <Input
          className="input-line"
          type="text"
          placeholder="Username"
          value={username}
          style={{ marginBottom: 22 }}
          onChange={(e) => setUsername(e.target.value)}
        />
        <br />
        <Input
          className="input-line"
          type="password"
          placeholder="Password"
          style={{ marginBottom: 6 }}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <br />
        <div className="input-down">
          <Checkbox className="remember-me">Remember me</Checkbox>
          <Button
            className="forgot-btn"
            appearance="subtle"
            onClick={() => forgotPassword(username)}
          >
            Forgot Password
          </Button>
        </div>
        <br />
        <Button
          className="sign-in-btn"
          onClick={() => signIn(username, password)}
        >
          Sign In
        </Button>
        <br />
        <Link to="https://www.vogic.ai" className="help-support">
          <p>Help | Support</p>
        </Link>
      </div>
    </div>
  );
};

export default ImageWithText;
