import React, { useEffect, useState } from "react";

import Appliance from "./Appliance";
import "./index.css";

const LivePage = (props) => {
  const [animation, setAnimation] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimation(true);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className={`appliance-view ${animation ? "active" : ""}`}>
      <Appliance
        appliances={props.appliances}
        cameraType={props.cameraType}
        searchQuery={props.searchQuery}
        sortBy={props.sortBy}
      />
    </div>
  );
};

export default LivePage;
