import React, { useEffect, useState } from "react";
import Alert from "./Alert";
import "./index.css";

const LivePage = () => {
  const [animation, setAnimation] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimation(true);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className={`alert-view ${animation ? "active" : ""}`}>
      <Alert />
    </div>
  );
};

export default LivePage;
