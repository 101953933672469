import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "rsuite";
import { App, Input } from "antd";
import axios from "axios";
import styled from "styled-components";
import { VideoCameraOutlined, SettingOutlined } from "@ant-design/icons";
import Appliance from "./Appliance";
import CameraTable from "./CameraTable";
import Toggle from "./../../Components/StatusToggle";
import SortBySelect from "./../../Components/SortBySelect";
import backendAPI from "./../../utils/backendAPI";
import LocationIcon from "./Icons/Location";

import "./header.css";

const LocationInput = styled(Input)`
  .ant-input {
    border-radius: 60px;
    border: 1px solid var(--neutral-gray-300, #ced2da);
    background: var(--gray-white, #fff);
    border-right: none;
  }
  .ant-input-group-addon {
    border-radius: 60px;
    border: 1px solid var(--neutral-gray-300, #ced2da);
    background: var(--gray-white, #fff);
    border-left: none;
  }
`;

const countFunc = (list, property, value = true) => {
  try {
    const count = list.reduce((acc, obj) => {
      if (obj[property] === value) {
        return acc + 1;
      }
      return acc;
    }, 0);
    return count;
  } catch (err) {
    return 0;
  }
};

const Header = () => {
  const [animation, setAnimation] = useState(false);
  const [isCameraActive, setCameraActive] = useState(true);
  const [cameraType, setCameraType] = useState(false);
  const [sortBy, setSortBy] = useState("forward");
  const [cameras, setCameras] = useState([]);
  const [appliances, setAppliances] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const leftToRightAnimation = (isCameraActive) => {
    if (!isCameraActive) {
      const timer = setTimeout(() => {
        setCameraActive(!isCameraActive);
      }, 100);

      return () => {
        clearTimeout(timer);
      };
    }
  };
  const rightToLeftAnimation = (isCameraActive) => {
    if (isCameraActive) {
      const timer = setTimeout(() => {
        setCameraActive(!isCameraActive);
      }, 100);

      return () => {
        clearTimeout(timer);
      };
    }
  };

  useEffect(() => {
    const loadCameras = async () => {
      try {
        const response = await axios.get(
          `${backendAPI.address}/api/v1/cameras/`
        );
        setCameras(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    const loadAppliances = async () => {
      try {
        const response = await axios.get(
          `${backendAPI.address}/api/v1/appliances/`
        );
        setAppliances(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    const timer = setTimeout(() => {
      setAnimation(true);
    }, 1000);

    loadCameras();
    loadAppliances();

    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <div>
      <div className="device-header">
        <div
          className={`section ${isCameraActive ? "active" : "disabled"}`}
          onClick={() => rightToLeftAnimation(isCameraActive)}
        >
          <div
            className={`title-text ${!isCameraActive ? "active" : "disabled"}`}
          >
            <SettingOutlined style={{ fontSize: 50 }} /> {appliances.length}{" "}
            Appliances
          </div>
          <div
            className={`count-info ${!isCameraActive ? "active" : "disabled"}`}
          >
            <span
              className={`online-text ${
                !isCameraActive ? "active" : "disabled"
              }`}
            >
              {countFunc(appliances, "connection_status")}
            </span>{" "}
            Online |{" "}
            <span
              className={`offline-text ${
                !isCameraActive ? "active" : "disabled"
              }`}
            >
              {countFunc(appliances, "connection_status", false)}
            </span>{" "}
            Offline
          </div>
          <Link
            to="/registerAppliance"
            style={{ textDecoration: "none", display: "flex", width: 300 }}
          >
            <Button
              className={`add-btn ${!isCameraActive ? "active" : "disabled"}`}
            >
              + Register new Appliance
            </Button>
          </Link>
        </div>
        <div
          className={`section ${isCameraActive ? "disabled" : "active"}`}
          onClick={() => leftToRightAnimation(isCameraActive)}
        >
          <div
            className={`title-text ${isCameraActive ? "active" : "disabled"}`}
          >
            <VideoCameraOutlined style={{ fontSize: 50 }} /> {cameras.length}{" "}
            Cameras
          </div>
          <div
            className={`count-info ${isCameraActive ? "active" : "disabled"}`}
          >
            <span
              className={`online-text ${
                isCameraActive ? "active" : "disabled"
              }`}
            >
              {countFunc(cameras, "lan_connectivity_status")}
            </span>{" "}
            Online |{" "}
            <span
              className={`offline-text ${
                isCameraActive ? "active" : "disabled"
              }`}
            >
              {countFunc(cameras, "lan_connectivity_status", false)}
            </span>{" "}
            Offline
          </div>
          <Link
            to="/addCamera"
            style={{ textDecoration: "none", display: "flex", width: 300 }}
          >
            <Button
              className={`add-btn ${isCameraActive ? "active" : "disabled"}`}
            >
              + Add new Camera
            </Button>
          </Link>
        </div>
        <div className={`device-query ${animation ? "active" : ""}`}>
          <div className={`search-location ${isCameraActive ? "active" : ""}`}>
            <LocationInput
              value={searchQuery}
              onChange={(event) => {
                setSearchQuery(event.target.value);
              }}
              addonAfter={<LocationIcon />}
              placeholder="Search by Location"
            />
          </div>
          <Toggle
            onChange={() => setCameraType(!cameraType)}
            bgCol={cameraType}
          />
          <span className="toggle-text">Include offline Cameras</span>
          <SortBySelect
            style={{ marginLeft: 40 }}
            onChange={(value) => {
              setSortBy(value);
            }}
            value={sortBy}
            options={[
              {
                value: "forward",
                label: (
                  <span className="select-text-1">
                    Sort by: <span className="select-text-2">A to Z</span>
                  </span>
                ),
              },
              {
                value: "backward",
                label: (
                  <span className="select-text-1">
                    Sort by: <span className="select-text-2">Z to A</span>
                  </span>
                ),
              },
            ]}
          />
        </div>
      </div>
      <div className={`device-main ${animation ? "active" : ""}`}>
        <div className="device-table">
          {isCameraActive ? (
            <CameraTable
              cameras={cameras}
              cameraType={cameraType}
              sortBy={sortBy === "forward"}
            />
          ) : (
            <Appliance
              appliances={appliances}
              cameraType={cameraType}
              searchQuery={searchQuery}
              sortBy={sortBy === "forward"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
