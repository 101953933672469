import React from "react";

const Warning = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.61666 16.2676C4.10832 16.2676 3.63332 16.0926 3.29166 15.7676C2.85832 15.3593 2.64999 14.7426 2.72499 14.0759L3.03332 11.3759C3.09166 10.8676 3.39999 10.1926 3.75832 9.82594L10.6 2.58427C12.3083 0.775936 14.0917 0.725936 15.9 2.43427C17.7083 4.1426 17.7583 5.92594 16.05 7.73427L9.20832 14.9759C8.85832 15.3509 8.20832 15.7009 7.69999 15.7843L5.01666 16.2426C4.87499 16.2509 4.74999 16.2676 4.61666 16.2676ZM13.275 2.42594C12.6333 2.42594 12.075 2.82594 11.5083 3.42594L4.66666 10.6759C4.49999 10.8509 4.30832 11.2676 4.27499 11.5093L3.96666 14.2093C3.93332 14.4843 3.99999 14.7093 4.14999 14.8509C4.29999 14.9926 4.52499 15.0426 4.79999 15.0009L7.48332 14.5426C7.72499 14.5009 8.12499 14.2843 8.29166 14.1093L15.1333 6.8676C16.1667 5.7676 16.5417 4.75094 15.0333 3.33427C14.3667 2.6926 13.7917 2.42594 13.275 2.42594Z"
        fill="#637083"
      />
      <path
        d="M14.4507 9.12601C14.434 9.12601 14.409 9.12601 14.3924 9.12601C11.7924 8.86768 9.70069 6.89268 9.30069 4.30935C9.25069 3.96768 9.48402 3.65101 9.82569 3.59268C10.1674 3.54268 10.484 3.77601 10.5424 4.11768C10.859 6.13435 12.4924 7.68435 14.5257 7.88435C14.8674 7.91768 15.1174 8.22601 15.084 8.56768C15.0424 8.88435 14.7674 9.12601 14.4507 9.12601Z"
        fill="#637083"
      />
      <path
        d="M17.5 18.959H2.5C2.15833 18.959 1.875 18.6757 1.875 18.334C1.875 17.9923 2.15833 17.709 2.5 17.709H17.5C17.8417 17.709 18.125 17.9923 18.125 18.334C18.125 18.6757 17.8417 18.959 17.5 18.959Z"
        fill="#637083"
      />
    </svg>
  );
};

export default Warning;
