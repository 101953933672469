import React from "react";

const Warning = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00033 8.50065C5.88699 8.50065 4.16699 6.78065 4.16699 4.66732C4.16699 2.55398 5.88699 0.833984 8.00033 0.833984C10.1137 0.833984 11.8337 2.55398 11.8337 4.66732C11.8337 6.78065 10.1137 8.50065 8.00033 8.50065ZM8.00033 1.83398C6.44033 1.83398 5.16699 3.10732 5.16699 4.66732C5.16699 6.22732 6.44033 7.50065 8.00033 7.50065C9.56033 7.50065 10.8337 6.22732 10.8337 4.66732C10.8337 3.10732 9.56033 1.83398 8.00033 1.83398Z"
        fill="#344051"
      />
      <path
        d="M2.27344 15.1667C2.0001 15.1667 1.77344 14.94 1.77344 14.6667C1.77344 11.82 4.56679 9.5 8.00012 9.5C8.27346 9.5 8.50012 9.72667 8.50012 10C8.50012 10.2733 8.27346 10.5 8.00012 10.5C5.12012 10.5 2.77344 12.3667 2.77344 14.6667C2.77344 14.94 2.54677 15.1667 2.27344 15.1667Z"
        fill="#344051"
      />
      <path
        d="M12.1333 14.7667C10.68 14.7667 9.5 13.5867 9.5 12.1333C9.5 10.68 10.68 9.5 12.1333 9.5C13.5867 9.5 14.7667 10.68 14.7667 12.1333C14.7667 13.5867 13.5867 14.7667 12.1333 14.7667ZM12.1333 10.5C11.2333 10.5 10.5 11.2333 10.5 12.1333C10.5 13.0333 11.2333 13.7667 12.1333 13.7667C13.0333 13.7667 13.7667 13.0333 13.7667 12.1333C13.7667 11.2333 13.0333 10.5 12.1333 10.5Z"
        fill="#344051"
      />
      <path
        d="M14.666 15.167C14.5393 15.167 14.4126 15.1203 14.3126 15.0203L13.646 14.3537C13.4526 14.1603 13.4526 13.8403 13.646 13.647C13.8393 13.4536 14.1593 13.4536 14.3527 13.647L15.0193 14.3136C15.2127 14.507 15.2127 14.827 15.0193 15.0203C14.9193 15.1203 14.7927 15.167 14.666 15.167Z"
        fill="#344051"
      />
    </svg>
  );
};

export default Warning;
