import React, { useEffect, useState } from "react";

import EditAppliance from "./EditAppliance";
import "./index.css";

const EditApplianceAnimation = () => {
  const [animation, setAnimation] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimation(true);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className={`edit-appliance-view ${animation ? "active" : ""}`}>
      <EditAppliance />
    </div>
  );
};

export default EditApplianceAnimation;
