import React from "react";
import styled from "styled-components";

import LogoIcon from "./Icons/Logo";
import SmallLogo from "./Icons/SmallLogo";

const LogoWrapper = styled.div({
  margin: "20px 80px 20px 40px",
});

const Logo = (props) => {
  return (
    <LogoWrapper>
      {props.headerState ? <LogoIcon /> : <SmallLogo />}
    </LogoWrapper>
  );
};

export default Logo;
