import React from "react";

const Group17 = (props) => {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.75 14.7188H1.25C0.99375 14.7188 0.78125 14.5063 0.78125 14.25C0.78125 13.9938 0.99375 13.7812 1.25 13.7812H13.75C14.0063 13.7812 14.2188 13.9938 14.2188 14.25C14.2188 14.5063 14.0063 14.7188 13.75 14.7188Z"
        fill="#637083"
      />
      <path
        d="M13.125 14.7188H1.875C1.61875 14.7188 1.40625 14.5063 1.40625 14.25V4.25C1.40625 2.3625 2.4875 1.28125 4.375 1.28125H10.625C12.5125 1.28125 13.5938 2.3625 13.5938 4.25V14.25C13.5938 14.5063 13.3813 14.7188 13.125 14.7188ZM2.34375 13.7812H12.6562V4.25C12.6562 2.8625 12.0125 2.21875 10.625 2.21875H4.375C2.9875 2.21875 2.34375 2.8625 2.34375 4.25V13.7812Z"
        fill="#637083"
      />
      <path
        d="M6.25 11.2812H4.375C4.11875 11.2812 3.90625 11.0688 3.90625 10.8125C3.90625 10.5563 4.11875 10.3438 4.375 10.3438H6.25C6.50625 10.3438 6.71875 10.5563 6.71875 10.8125C6.71875 11.0688 6.50625 11.2812 6.25 11.2812Z"
        fill="#637083"
      />
      <path
        d="M10.625 11.2812H8.75C8.49375 11.2812 8.28125 11.0688 8.28125 10.8125C8.28125 10.5563 8.49375 10.3438 8.75 10.3438H10.625C10.8812 10.3438 11.0938 10.5563 11.0938 10.8125C11.0938 11.0688 10.8812 11.2812 10.625 11.2812Z"
        fill="#637083"
      />
      <path
        d="M6.25 8.46875H4.375C4.11875 8.46875 3.90625 8.25625 3.90625 8C3.90625 7.74375 4.11875 7.53125 4.375 7.53125H6.25C6.50625 7.53125 6.71875 7.74375 6.71875 8C6.71875 8.25625 6.50625 8.46875 6.25 8.46875Z"
        fill="#637083"
      />
      <path
        d="M10.625 8.46875H8.75C8.49375 8.46875 8.28125 8.25625 8.28125 8C8.28125 7.74375 8.49375 7.53125 8.75 7.53125H10.625C10.8812 7.53125 11.0938 7.74375 11.0938 8C11.0938 8.25625 10.8812 8.46875 10.625 8.46875Z"
        fill="#637083"
      />
      <path
        d="M6.25 5.65625H4.375C4.11875 5.65625 3.90625 5.44375 3.90625 5.1875C3.90625 4.93125 4.11875 4.71875 4.375 4.71875H6.25C6.50625 4.71875 6.71875 4.93125 6.71875 5.1875C6.71875 5.44375 6.50625 5.65625 6.25 5.65625Z"
        fill="#637083"
      />
      <path
        d="M10.625 5.65625H8.75C8.49375 5.65625 8.28125 5.44375 8.28125 5.1875C8.28125 4.93125 8.49375 4.71875 8.75 4.71875H10.625C10.8812 4.71875 11.0938 4.93125 11.0938 5.1875C11.0938 5.44375 10.8812 5.65625 10.625 5.65625Z"
        fill="#637083"
      />
    </svg>
  );
};

export default Group17;
