import React, { useState } from "react";
import styled from "styled-components";
import { useLocation, Link } from "react-router-dom";

import StoryIcon from "./Icons/Stories";
import CaseIcon from "./Icons/Cases";
import AlertIcon from "./Icons/Alerts";

const SidebarWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 70px;
  width: 104px;
  background: #f9fafb;
  height: calc(100% - 70px);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 5% 0;
`;

const SidebarTab = styled(Link)`
  text-decoration: none !important;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: ${({ color }) => (color ? "#fe6601" : "#414E62")} !important;
  gap: 10px;
  cursor: pointer;
  &:hover {
    color: #fe6601 !important;
    svg {
      stroke: #ff6600;
      fill: #ff6600;
    }
    span {
      color: #fe6601 !important;
    }
  }
`;

const SidebarTabName = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: 0.025em;
`;

const Sidebar = () => {
  const pathname = useLocation().pathname;
  return (
    <SidebarWrapper>
      <SidebarTab to="/story" color={pathname === "/story"}>
        <StoryIcon fill={`${pathname === "/story" ? "#ff6600" : "#414E62"}`} />
        <SidebarTabName>Stories</SidebarTabName>
      </SidebarTab>
      <SidebarTab
        to="/case"
        color={pathname === "/case" || pathname === "/createCase"}
      >
        <CaseIcon
          fill={`${
            pathname === "/case" || pathname === "/createCase"
              ? "#ff6600"
              : "#414E62"
          }`}
        />
        <SidebarTabName>Cases</SidebarTabName>
      </SidebarTab>
      <SidebarTab
        to="/alert"
        color={pathname === "/alert" || pathname === "/createAlert"}
      >
        <AlertIcon
          fill={`${
            pathname === "/alert" || pathname === "/createAlert"
              ? "#ff6600"
              : "#414E62"
          }`}
        />
        <SidebarTabName>Alerts</SidebarTabName>
      </SidebarTab>
    </SidebarWrapper>
  );
};

export default Sidebar;
