import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import HeaderWrapper from "./HeaderWrapper";
import HeaderLink from "./HeaderLink";
import UserWrapper from "./UserWrapper";
import Navbar from "./Navbar";
import Logo from "./Logo";
import Avatar from "./Avatar";
import Notifi from "./Notifi";

import LiveIcon from "./Icons/Live";
import SearchIcon from "./Icons/Search";
import EventsIcon from "./Icons/Events";
import DevicesIcon from "./Icons/Devices";
import InsightsIcon from "./Icons/Insights";

const Header = () => {
  const pathname = useLocation().pathname;
  const [headerState, setHeader] = useState(false);
  return (
    <HeaderWrapper
      onMouseEnter={() => {
        setHeader(true);
      }}
      onMouseLeave={() => {
        setHeader(false);
      }}
      headerState={headerState}
    >
      <Navbar headerState={headerState}>
        <Logo headerState={headerState} />
        <HeaderLink to="/live" isactive={(pathname === "/live").toString()}>
          <LiveIcon />
          <span>Live</span>
        </HeaderLink>
        <HeaderLink
          to="/search"
          isactive={(
            pathname === "/search" || pathname === "/result"
          ).toString()}
        >
          <SearchIcon />
          <span>Search</span>
        </HeaderLink>
        <HeaderLink
          to="/story"
          isactive={(
            pathname === "/story" ||
            pathname === "/case" ||
            pathname === "/alert"
          ).toString()}
        >
          <EventsIcon />
          <span>Events</span>
        </HeaderLink>
        <HeaderLink
          to="/device"
          isactive={(
            pathname === "/device" ||
            pathname === "/addCamera" ||
            pathname === "/registerAppliance" ||
            pathname.includes("/editAppliance")
          ).toString()}
        >
          <DevicesIcon />
          <span>Devices</span>
        </HeaderLink>
        <HeaderLink
          to="/insight"
          isactive={(
            pathname === "/insight" || pathname === "/createWidget"
          ).toString()}
        >
          <InsightsIcon />
          <span>Insights</span>
        </HeaderLink>
      </Navbar>
      <UserWrapper>
        <Notifi />
        <Avatar />
      </UserWrapper>
    </HeaderWrapper>
  );
};

export default Header;
