
import React from 'react';

const Events = (props)=> {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M15 18.4375C13.1 18.4375 11.5625 16.9 11.5625 15C11.5625 13.1 13.1 11.5625 15 11.5625C16.9 11.5625 18.4375 13.1 18.4375 15C18.4375 16.9 16.9 18.4375 15 18.4375ZM15 13.4375C14.1375 13.4375 13.4375 14.1375 13.4375 15C13.4375 15.8625 14.1375 16.5625 15 16.5625C15.8625 16.5625 16.5625 15.8625 16.5625 15C16.5625 14.1375 15.8625 13.4375 15 13.4375Z" 
        />
        <path d="M25.0004 23.4374C24.8004 23.4374 24.6128 23.3749 24.4378 23.2499C24.0253 22.9374 23.9378 22.3498 24.2503 21.9373C25.7628 19.9248 26.5629 17.5249 26.5629 14.9999C26.5629 12.4749 25.7628 10.0749 24.2503 8.06238C23.9378 7.64988 24.0253 7.06236 24.4378 6.74986C24.8503 6.43736 25.4379 6.52485 25.7504 6.93735C27.5129 9.27485 28.4379 12.0624 28.4379 14.9999C28.4379 17.9374 27.5129 20.7249 25.7504 23.0624C25.5629 23.3124 25.2879 23.4374 25.0004 23.4374Z"
        />
        <path d="M5 23.4374C4.7125 23.4374 4.43749 23.3124 4.24999 23.0624C2.48749 20.7249 1.5625 17.9374 1.5625 14.9999C1.5625 12.0624 2.48749 9.27485 4.24999 6.93735C4.56249 6.52485 5.15001 6.43736 5.56251 6.74986C5.97501 7.06236 6.06251 7.64988 5.75001 8.06238C4.23751 10.0749 3.4375 12.4749 3.4375 14.9999C3.4375 17.5249 4.23751 19.9248 5.75001 21.9373C6.06251 22.3498 5.97501 22.9374 5.56251 23.2499C5.40001 23.3749 5.2 23.4374 5 23.4374Z" 
        />
        <path d="M21.0001 20.4374C20.8001 20.4374 20.6126 20.3749 20.4376 20.2499C20.0251 19.9374 19.9376 19.3498 20.2501 18.9373C21.1126 17.7998 21.5626 16.4374 21.5626 14.9999C21.5626 13.5624 21.1126 12.1999 20.2501 11.0624C19.9376 10.6499 20.0251 10.0623 20.4376 9.74984C20.8501 9.43734 21.4376 9.52487 21.7501 9.93737C22.8501 11.4124 23.4376 13.1624 23.4376 14.9999C23.4376 16.8374 22.8501 18.5998 21.7501 20.0623C21.5626 20.3123 21.2876 20.4374 21.0001 20.4374Z" 
        />
        <path d="M9.00002 20.4374C8.71252 20.4374 8.43751 20.3123 8.25001 20.0623C7.15001 18.5873 6.5625 16.8374 6.5625 14.9999C6.5625 13.1624 7.15001 11.3999 8.25001 9.93737C8.56251 9.52487 9.14999 9.43734 9.56249 9.74984C9.97499 10.0623 10.0625 10.6499 9.74998 11.0624C8.88748 12.1999 8.4375 13.5624 8.4375 14.9999C8.4375 16.4374 8.88748 17.7998 9.74998 18.9373C10.0625 19.3498 9.97499 19.9374 9.56249 20.2499C9.39999 20.3749 9.20002 20.4374 9.00002 20.4374Z" 
        />
    </svg>
  );
};

export default Events;
