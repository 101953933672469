import { Select } from "antd";
import styled from "styled-components";

export default styled(Select)`
  .ant-select-selector {
    border-radius: 8px !important;
    border: 1px solid var(--neutral-gray-500, #637083) !important;
  }
  background: var(--basic-white, #fff);
  display: flex;
  width: 155px;
`;
