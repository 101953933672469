const data = [
  {
    name: "Jill Jones",
    role: "Admin",
    id: "UFG24556",
    email: "email@address.com",
    number: "+ 39 002 817 7263",
    login: "ACTIVE",
    location: "Home, Oakland Zoo, City Hall",
    access: "Full Access",
  },
  {
    name: "Alision Miller",
    role: "Location Manager",
    id: "UFG24556",
    email: "email@address.com",
    number: "+ 39 002 817 7263",
    login: "OFFLINE",
    time: "03:57 PM(IST)",
    date: "08.05.2023",
    location: "Home, Oakland Zoo",
    access: "Limited Access",
  },
  {
    name: "John Doe",
    role: "Admin",
    id: "UFG24556",
    email: "email@address.com",
    number: "+ 39 002 817 7263",
    login: "OFFLINE",
    time: "03:57 PM(IST)",
    date: "08.05.2023",
    location: "Home",
    access: "Full Access",
  },
  {
    name: "Name Surname",
    role: "Admin",
    id: "UFG24556",
    email: "email@address.com",
    number: "+ 39 002 817 7263",
    login: "OFFLINE",
    time: "03:57 PM(IST)",
    date: "08.05.2023",
    location: "Home, Oakland Zoo",
    access: "Full Access",
  },
  {
    name: "Name Surname",
    role: "Role",
    id: "UFG24556",
    email: "email@address.com",
    number: "+ 39 002 817 7263",
    login: "OFFLINE",
    time: "03:57 PM(IST)",
    date: "08.05.2023",
    location: "Home, Oakland Zoo, City Hall",
    access: "Full Access",
  },
];

export default data;
