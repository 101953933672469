import React from "react";
import styled from "styled-components";

const CustomDiv = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0px;
  justify-content: space-between;
  padding-bottom: 37px;
  border-bottom: 1px solid #e4e7ec;
`;

const HeaderTitle = styled.div`
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  color: #344051;
  margin-right: 40px;
`;

const Header = () => {
  return (
    <HeaderWrapper>
      <CustomDiv>
        <HeaderTitle>Create new User</HeaderTitle>
      </CustomDiv>
    </HeaderWrapper>
  );
};

export default Header;
