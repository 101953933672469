import React, { useEffect, useState } from "react";
import Case from "./Case";
import "./index.css";

const LivePage = () => {
  const [animation, setAnimation] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimation(true);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className={`case-view ${animation ? "active" : ""}`}>
      <Case />
    </div>
  );
};

export default LivePage;
