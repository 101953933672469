import React from "react";

const Warning = (props) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M20 28.4375H10C5.4375 28.4375 2.8125 25.8125 2.8125 21.25V8.75C2.8125 4.1875 5.4375 1.5625 10 1.5625H20C24.5625 1.5625 27.1875 4.1875 27.1875 8.75V21.25C27.1875 25.8125 24.5625 28.4375 20 28.4375ZM10 3.4375C6.425 3.4375 4.6875 5.175 4.6875 8.75V21.25C4.6875 24.825 6.425 26.5625 10 26.5625H20C23.575 26.5625 25.3125 24.825 25.3125 21.25V8.75C25.3125 5.175 23.575 3.4375 20 3.4375H10Z" />
      <path d="M11.25 13.8875C11.0375 13.8875 10.825 13.85 10.625 13.7625C10.05 13.5125 9.6875 12.95 9.6875 12.3375V2.5C9.6875 1.9875 10.1125 1.5625 10.625 1.5625H19.375C19.8875 1.5625 20.3125 1.9875 20.3125 2.5V12.325C20.3125 12.95 19.95 13.5125 19.375 13.75C18.8125 14 18.15 13.8875 17.6875 13.4625L15 11L12.3125 13.475C12.0125 13.75 11.6375 13.8875 11.25 13.8875ZM15 9.01253C15.375 9.01253 15.7625 9.14997 16.0625 9.42497L18.4375 11.6125V3.4375H11.5625V11.6125L13.9375 9.42497C14.2375 9.14997 14.625 9.01253 15 9.01253Z" />
      <path d="M21.875 18.4375H16.5625C16.05 18.4375 15.625 18.0125 15.625 17.5C15.625 16.9875 16.05 16.5625 16.5625 16.5625H21.875C22.3875 16.5625 22.8125 16.9875 22.8125 17.5C22.8125 18.0125 22.3875 18.4375 21.875 18.4375Z" />
      <path d="M21.875 23.4375H11.25C10.7375 23.4375 10.3125 23.0125 10.3125 22.5C10.3125 21.9875 10.7375 21.5625 11.25 21.5625H21.875C22.3875 21.5625 22.8125 21.9875 22.8125 22.5C22.8125 23.0125 22.3875 23.4375 21.875 23.4375Z" />
    </svg>
  );
};

export default Warning;
