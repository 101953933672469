import React from "react";

const Warning = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9921 18.0491C12.8337 18.0491 12.6754 17.9908 12.5504 17.8658C12.3087 17.6241 12.3087 17.2241 12.5504 16.9824L14.9004 14.6324C15.1421 14.3908 15.5421 14.3908 15.7837 14.6324C16.0254 14.8741 16.0254 15.2741 15.7837 15.5158L13.4337 17.8658C13.3087 17.9908 13.1504 18.0491 12.9921 18.0491Z"
        fill="#637083"
      />
      <path
        d="M15.3421 18.0491C15.1837 18.0491 15.0254 17.9908 14.9004 17.8658L12.5504 15.5158C12.3087 15.2741 12.3087 14.8741 12.5504 14.6324C12.7921 14.3908 13.1921 14.3908 13.4337 14.6324L15.7837 16.9824C16.0254 17.2241 16.0254 17.6241 15.7837 17.8658C15.6587 17.9908 15.5004 18.0491 15.3421 18.0491Z"
        fill="#637083"
      />
      <path
        d="M10.1331 9.68268C10.1081 9.68268 10.0915 9.68268 10.0665 9.68268C10.0248 9.67435 9.96647 9.67435 9.91647 9.68268C7.4998 9.60768 5.6748 7.70768 5.6748 5.36602C5.6748 2.98268 7.61647 1.04102 9.9998 1.04102C12.3831 1.04102 14.3248 2.98268 14.3248 5.36602C14.3165 7.70768 12.4831 9.60768 10.1581 9.68268C10.1498 9.68268 10.1415 9.68268 10.1331 9.68268ZM9.9998 2.29102C8.30814 2.29102 6.92481 3.67435 6.92481 5.36602C6.92481 7.03268 8.2248 8.37435 9.88314 8.43268C9.93314 8.42435 10.0415 8.42435 10.1498 8.43268C11.7831 8.35768 13.0665 7.01602 13.0748 5.36602C13.0748 3.67435 11.6915 2.29102 9.9998 2.29102Z"
        fill="#637083"
      />
      <path
        d="M10.0003 18.7991C8.30026 18.7991 6.69193 18.3574 5.47526 17.5408C4.31693 16.7658 3.68359 15.7074 3.68359 14.5658C3.68359 13.4241 4.32526 12.3741 5.47526 11.6074C7.96693 9.94076 12.0169 9.94076 14.5086 11.6074C14.7919 11.7991 14.8753 12.1908 14.6836 12.4741C14.4919 12.7658 14.1003 12.8408 13.8169 12.6491C11.7419 11.2658 8.24193 11.2658 6.16693 12.6491C5.36693 13.1824 4.93359 13.8574 4.93359 14.5658C4.93359 15.2741 5.37526 15.9658 6.16693 16.4991C7.17526 17.1741 8.53359 17.5408 9.99193 17.5408C10.3336 17.5408 10.6169 17.8241 10.6169 18.1658C10.6169 18.5074 10.3419 18.7991 10.0003 18.7991Z"
        fill="#292D32"
      />
    </svg>
  );
};

export default Warning;
