import React from "react";

const Warning = (props) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M19.05 28.4375H10.95C8.58752 28.4375 6.86252 27.45 6.20002 25.75C5.51252 23.975 6.15002 21.775 7.80002 20.2875L13.6 15L7.80002 9.7125C6.15002 8.225 5.51252 6.025 6.20002 4.25C6.86252 2.5375 8.58752 1.5625 10.95 1.5625H19.05C21.4125 1.5625 23.1375 2.55 23.8 4.25C24.4875 6.025 23.85 8.225 22.2 9.7125L16.4 15L22.2125 20.2875C23.85 21.775 24.5 23.975 23.8125 25.75C23.1375 27.45 21.4125 28.4375 19.05 28.4375ZM15 16.2625L9.06252 21.6625C8.01252 22.625 7.55002 24.025 7.95002 25.0625C8.32502 26.025 9.38752 26.5625 10.95 26.5625H19.05C20.6125 26.5625 21.675 26.0375 22.05 25.0625C22.45 24.025 22 22.625 20.9375 21.6625L15 16.2625ZM10.95 3.4375C9.38752 3.4375 8.32502 3.9625 7.95002 4.9375C7.55002 5.975 8.00002 7.375 9.06252 8.3375L15 13.7375L20.9375 8.3375C21.9875 7.375 22.45 5.975 22.05 4.9375C21.675 3.975 20.6125 3.4375 19.05 3.4375H10.95Z" />
    </svg>
  );
};

export default Warning;
