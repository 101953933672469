import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Input, Select, Checkbox } from "antd";
import { VideoCameraOutlined } from "@ant-design/icons";
import { Button } from "rsuite";
import CameraSelect from "./CameraSelect";

const data = [
  {
    label: "All Cameras",
    value: "All Cameras",
    children: [
      {
        label: "Parking Lot",
        value: "Parking Lot",
        children: [
          { label: "Camera 1", value: "Camera 1" },
          { label: "Camera 2", value: "Camera 2" },
          { label: "Camera 3", value: "Camera 3" },
          { label: "Camera 4", value: "Camera 4" },
        ],
      },
      { label: "Main Entrance", value: "Main Entrance", children: [] },
    ],
  },
];

const MainWrapper = styled.div`
  margin-top: 46px;
  display: flex;
  gap: 270px;
`;
const SubTitle = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #202b37;
`;
const Property = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #97a1af;
  margin-bottom: 4px;
`;
const MidText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #344051;
`;
const MyInput = styled(Input)`
  width: 200px;
  height: 40px;
  border: 1px solid #94a3b8;
  border-radius: 8px;
`;
const UserInfo = styled.div`
  display: flex;
  gap: 20px;
`;

const MyButtons = styled.div`
  margin-top: 90px;
  display: flex;
  width: calc(100% - 140px);
  justify-content: space-between;
  padding-bottom: 80px;
`;
const BackBtn = styled(Button)`
  padding: 16px !important;
  gap: 8px !important;
  width: 115px !important;
  height: 56px !important;
  border: 1px solid #414e62 !important;
  border-radius: 8px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #0f172a !important;
`;
const NextBtn = styled(Button)`
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #ffffff !important;
  padding: 16px !important;
  gap: 8px !important;
  position: absolute !important;
  width: 130px !important;
  height: 56px !important;
  background: #40bf7f !important;
  border: 1px solid #40bf7f !important;
  border-radius: 8px !important;
`;
const MySelect = styled(Select)`
  width: 200px;
  border: 1px solid #94a3b8;
  border-radius: 8px;
  margin-right: 20px;
  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }
  .ant-select-selector {
    height: 40px !important;
  }
`;

const ConfirmBtn = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #ff6600;
  padding: 18px 16px 8px 16px;
  cursor: pointer;
`;

const Extra = styled.div`
  display: flex;
  justify-content: end;
  border-width: 1px 0px;
  border-style: solid;
  border-color: #e4e7ec;
`;

const Main = () => {
  const cameraChange = (value) => {};
  return (
    <div>
      <MainWrapper>
        <div>
          <SubTitle style={{ marginBottom: 31 }}>
            Please fill out of the form
          </SubTitle>
          <UserInfo>
            <div>
              <Property>First Name</Property>
              <MyInput placeholder="Name" />
            </div>
            <div>
              <Property>Last Name</Property>
              <MyInput placeholder="Surname" />
            </div>
          </UserInfo>
          <UserInfo style={{ marginTop: 39 }}>
            <div>
              <Property>E-mail Address</Property>
              <MyInput placeholder="E-mail" />
            </div>
            <div>
              <Property>Phone Number</Property>
              <MyInput placeholder="Phone No." />
            </div>
          </UserInfo>
          <SubTitle style={{ marginTop: 39, marginBottom: 22 }}>
            Create username and password
          </SubTitle>
          <div>
            <Property>Username</Property>
            <MyInput placeholder="Username" />
          </div>
          <div style={{ marginTop: 39 }}>
            <Property>Password</Property>
            <MyInput placeholder="Strong Password" />
          </div>
          <div style={{ marginTop: 39 }}>
            <Property>Re-enter Password</Property>
            <MyInput placeholder="Strong Password" />
          </div>
        </div>
        <div>
          <SubTitle style={{ marginBottom: 55 }}>
            Please set 'Username's Role and Access
          </SubTitle>
          <MySelect placeholder="Assign to a Role" />
          <MySelect placeholder="Access Type" />
          <div
            style={{ marginTop: 39, marginBottom: 85, display: "flex", gap: 8 }}
          >
            <Checkbox />
            <MidText>Assign to a location</MidText>
          </div>
          <SubTitle>Choose Access to Cameras</SubTitle>
          <CameraSelect
            defaultExpandAll
            placeholder={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>Cameras</div>
                <div>
                  <VideoCameraOutlined />
                </div>
              </div>
            }
            data={data}
            onChange={cameraChange}
            renderExtraFooter={() => (
              <Extra>
                <ConfirmBtn>Confirm</ConfirmBtn>
              </Extra>
            )}
          />
        </div>
      </MainWrapper>
      <MyButtons>
        <Link to="/userManage">
          <BackBtn>Back</BackBtn>
        </Link>
        <Link to="/userManage">
          <NextBtn>Next</NextBtn>
        </Link>
      </MyButtons>
    </div>
  );
};

export default Main;
