import React from 'react';

const Notifi = (props)=> {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path d="M10.0165 17.1086C8.07487 17.1086 6.1332 16.8003 4.29154 16.1836C3.59154 15.9419 3.0582 15.4503 2.82487 14.8086C2.5832 14.1669 2.66654 13.4586 3.04987 12.8253L4.0082 11.2336C4.2082 10.9003 4.39154 10.2336 4.39154 9.84193V7.43359C4.39154 4.33359 6.91654 1.80859 10.0165 1.80859C13.1165 1.80859 15.6415 4.33359 15.6415 7.43359V9.84193C15.6415 10.2253 15.8249 10.9003 16.0249 11.2419L16.9749 12.8253C17.3332 13.4253 17.3999 14.1503 17.1582 14.8086C16.9165 15.4669 16.3915 15.9669 15.7332 16.1836C13.8999 16.8003 11.9582 17.1086 10.0165 17.1086ZM10.0165 3.05859C7.6082 3.05859 5.64154 5.01693 5.64154 7.43359V9.84193C5.64154 10.4503 5.39154 11.3503 5.0832 11.8753L4.12487 13.4669C3.94154 13.7753 3.89154 14.1003 3.99987 14.3753C4.09987 14.6586 4.34987 14.8753 4.69154 14.9919C8.17487 16.1586 11.8665 16.1586 15.3499 14.9919C15.6499 14.8919 15.8832 14.6669 15.9915 14.3669C16.0999 14.0669 16.0749 13.7419 15.9082 13.4669L14.9499 11.8753C14.6332 11.3336 14.3915 10.4419 14.3915 9.83359V7.43359C14.3915 5.01693 12.4332 3.05859 10.0165 3.05859Z" fill="#292D32"/>
        <path d="M11.5668 3.2834C11.5084 3.2834 11.4501 3.27507 11.3918 3.2584C11.1501 3.19173 10.9168 3.14173 10.6918 3.1084C9.98345 3.01673 9.30012 3.06673 8.65845 3.2584C8.42512 3.3334 8.17512 3.2584 8.01678 3.0834C7.85845 2.9084 7.80845 2.6584 7.90012 2.4334C8.24178 1.5584 9.07512 0.983398 10.0251 0.983398C10.9751 0.983398 11.8084 1.55007 12.1501 2.4334C12.2334 2.6584 12.1918 2.9084 12.0334 3.0834C11.9084 3.21673 11.7334 3.2834 11.5668 3.2834Z" fill="#292D32"/>
        <path d="M10.0167 19.0083C9.19166 19.0083 8.39166 18.675 7.80833 18.0916C7.225 17.5083 6.89166 16.7083 6.89166 15.8833H8.14166C8.14166 16.375 8.34166 16.8583 8.69166 17.2083C9.04166 17.5583 9.525 17.7583 10.0167 17.7583C11.05 17.7583 11.8917 16.9166 11.8917 15.8833H13.1417C13.1417 17.6083 11.7417 19.0083 10.0167 19.0083Z" fill="#292D32"/>
        <circle cx="15" cy="3" r="3" fill="#FF6600"/>
      </svg>
    );
};

export default Notifi;