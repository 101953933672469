import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import styled from "styled-components";
import GlobalStyle from "./global";
import Header from "./Components/Header";
import Sidebar from "./Components/Sidebar";
import LoginPage from "./Pages/Login";
import ForceRedirect from "./Components/Route/ForceRedirect";
import ProtectedRoute from "./Components/Route/ProtectedRoute";

import routes from "./utils/route";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const checkUserToken = () => {
    if (typeof window !== "undefined") {
      const user = localStorage.getItem("user-token");
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    }
  };
  useEffect(() => {
    checkUserToken();
  }, []);

  const Logout = () => {
    if (localStorage.getItem("user-token")) {
      localStorage.clear();
      setIsLoggedIn(false);
    }
  };

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path="/login"
            element={
              <ForceRedirect user={isLoggedIn}>
                <LoginPage />
              </ForceRedirect>
            }
          />
          {routes.type1.map((route, index) => (
            <Route
              exact
              path={route.path}
              element={
                <ProtectedRoute user={isLoggedIn}>
                  <Header />
                  <div>{route.element}</div>
                </ProtectedRoute>
              }
              key={"route-type1-" + index}
            />
          ))}
          {routes.type2.map((route, index) => (
            <Route
              exact
              key={"route-type3-" + index}
              path={route.path}
              element={
                <ProtectedRoute user={isLoggedIn}>
                  <Header />
                  <div style={{ display: "flex" }}>
                    <Sidebar />
                    <div style={{ marginTop: 100, marginLeft: 104 }}>
                      {route.element}
                    </div>
                  </div>
                </ProtectedRoute>
              }
            />
          ))}
          {routes.type3.map((route, index) => (
            <Route
              path={route.path}
              element={
                <ProtectedRoute user={isLoggedIn}>
                  {route.element}
                </ProtectedRoute>
              }
              key={"route-type2-" + index}
            />
          ))}
          <Route path="/" element={<Navigate to="/login" />} />
        </Routes>
        <GlobalStyle />
      </BrowserRouter>
    </div>
  );
}

export default App;
