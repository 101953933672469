import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button } from "rsuite";

const CustomDiv = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e4e7ec;
  margin: 0 40px;
  margin-top: 110px;
  padding-bottom: 37px;
`;

const HeaderTitle = styled.div`
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  color: #344051;
  margin-right: 40px;
`;
const ExchangeBtn = styled(Button)`
  padding: 10px 16px !important;
  gap: 8px !important;
  width: 122px !important;
  height: 45px !important;
  border: 1px solid #414e62 !important;
  border-radius: 8px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #0f172a !important;
  text-decoration: none !important;
`;
const AddNewUserBtn = styled(Button)`
  padding: 10px 16px !important;
  gap: 8px !important;
  width: 157px !important;
  height: 45px !important;
  background: #ff6600 !important;
  border-radius: 8px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #ffffff !important;
`;

const Header = () => {
  return (
    <HeaderWrapper>
      <CustomDiv>
        <HeaderTitle>User Management</HeaderTitle>
        <Link to="/userManage">
          <ExchangeBtn>All Users</ExchangeBtn>
        </Link>
      </CustomDiv>
      <Link to="/addUser">
        <AddNewUserBtn>Add new user</AddNewUserBtn>
      </Link>
    </HeaderWrapper>
  );
};

export default Header;
