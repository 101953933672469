import React, { useState, useEffect } from "react";

import Header from "./Header";
import Main from "./Main";
import "./index.css";

const Users = () => {
  const [animation, setAnimation] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimation(true);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <div
      className={`user-manage-view ${animation ? "active" : ""}`}
      style={{ padding: "0 40px", marginTop: 110 }}
    >
      <Header />
      <Main />
    </div>
  );
};

export default Users;
