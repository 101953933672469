import React from "react";
import styled from "styled-components";
import { SelectPicker } from "rsuite";
import { Tag } from "antd";
import CameraSelect from "./CameraSelect";
import UserSearch from "./Icons/UserSearch";
import RightArrow from "./Icons/RightArrow";
import data from "./data";

const CustomDiv = styled.div`
  display: flex;
  align-items: center;
`;

const dataUser = [
  {
    label: "Admin Users",
    value: "Admin Users",
    children: [
      {
        label: "User 1",
        value: "User 1",
      },
      {
        label: "User 2",
        value: "User 2",
      },
      {
        label: "User 3",
        value: "User 3",
      },
    ],
  },
  {
    label: "Managers",
    value: "Managers",
  },
];
const SortSelect = styled(SelectPicker)`
  .rs-picker-toggle-label {
    color: #637083 !important;
    font-weight: 400;
    font-size: 14px;
  }
  .rs-picker-toggle-value {
    color: #414e62 !important;
    font-weight: 700;
    font-size: 14px;
  }
  span.rs-picker-toggle-clean.rs-btn-close {
    display: none;
  }
  border: 1px solid #637083;
  border-radius: 8px;
`;
const MainWrapper = styled.div``;
const ConfirmBtn = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #ff6600;
  padding: 18px 16px 8px 16px;
  cursor: pointer;
`;

const Extra = styled.div`
  display: flex;
  justify-content: end;
  border-width: 1px 0px;
  border-style: solid;
  border-color: #e4e7ec;
`;

const NameText = styled.div`
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #202b37;
`;
const RoleText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #202b37;
  opacity: 0.4;
`;
const IdText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #202b37;
`;
const EmailText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #202b37;
`;
const NumberText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #414e62;
`;
const LocationText = styled.div`
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #414e62;
`;
const AccessText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #202b37;
  opacity: 0.4;
`;
const TimeText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #202b37;
`;
const DateText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
  opacity: 0.4;
`;

const MoreText = styled.div`
  font-weight: 700;
  font-size: 10px;
  line-height: 100%;
  letter-spacing: 0.025em;
  color: #828282;
  cursor: pointer;
`;
const Active = styled(Tag)`
  font-weight: 700;
  font-size: 10px;
  line-height: 100%;
  letter-spacing: 0.025em;
  color: #40bf7f;
  background: rgba(111, 207, 151, 0.25);
  border: 1px solid #40bf7f;
  border-radius: 29px;
  padding: 12px 22px;
`;

const Offline = styled(Tag)`
  background: rgba(227, 0, 0, 0.2);
  border: 1px solid #f20d0d;
  border-radius: 29px;
  font-weight: 700;
  font-size: 10px;
  line-height: 100%;
  letter-spacing: 0.025em;
  padding: 12px 22px;
  color: #f20d0d;
  margin-bottom: 8px;
`;

const LoginText = styled.div``;

const MainTable = styled.div`
  table {
    width: 100%;
    text-align: left;
    border-collapse: separate;
    border-spacing: 25px;
  }
  thead {
    width: 100%;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #414e62;
    opacity: 0.4;
  }
  tbody {
    width: 100%;
    tr {
      width: 100%;
      box-shadow: 1px 1px 6px -4px;
      height: 110px;
    }
  }
`;

const Main = () => {
  const cameraChange = (value) => {};
  return (
    <MainWrapper>
      <CustomDiv
        style={{
          marginTop: 50,
          justifyContent: "space-between",
          paddingBottom: 14,
          borderBottom: "1px solid #E4E7EC",
        }}
      >
        <CameraSelect
          defaultExpandAll
          placeholder={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>Search Users by Name or Role</div>
              <div>
                <UserSearch />
              </div>
            </div>
          }
          data={dataUser}
          onChange={cameraChange}
          renderExtraFooter={() => (
            <Extra>
              <ConfirmBtn>Confirm</ConfirmBtn>
            </Extra>
          )}
        />
        <SortSelect
          label="Sort by"
          data={[{ value: "A to Z", label: "A to Z" }]}
          style={{ width: 150, border: "1px solid #637083", borderRadius: 8 }}
          searchable={false}
          defaultValue="A to Z"
        />
      </CustomDiv>
      <MainTable>
        <table>
          <thead>
            <tr>
              <th style={{ paddingLeft: 44 }}>Name and Role</th>
              <th>User ID</th>
              <th>Email</th>
              <th>Contact Number</th>
              <th>Last Login</th>
              <th>Assigned Locations</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.map((dat, index) => (
              <tr>
                <td style={{ paddingLeft: 44 }}>
                  <NameText>{dat.name}</NameText>
                  <RoleText>{dat.role}</RoleText>
                </td>
                <td>
                  <IdText>{dat.id}</IdText>
                </td>
                <td>
                  <EmailText>{dat.email}</EmailText>
                </td>
                <td>
                  <NumberText>{dat.number}</NumberText>
                </td>
                <td>
                  {dat.login === "ACTIVE" ? (
                    <Active>ACTIVE</Active>
                  ) : (
                    <div>
                      <Offline>OFFLINE</Offline>
                      <TimeText>{dat.time}</TimeText>
                      <DateText>{dat.date}</DateText>
                    </div>
                  )}
                </td>
                <td>
                  <LocationText>{dat.location}</LocationText>
                  <AccessText>{dat.access}</AccessText>
                </td>
                <td style={{ paddingRight: 16 }}>
                  <CustomDiv style={{ gap: 4 }}>
                    <MoreText>MORE</MoreText>
                    <RightArrow />
                  </CustomDiv>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </MainTable>
    </MainWrapper>
  );
};

export default Main;
